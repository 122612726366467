import axios from "axios";
import store from "../redux/store";
import { logout } from "../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";

const api = axios.create({
  baseURL: "https://api.thirtyfirst.io/api/",
});

api.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 || error.response.status === 403) {
      store.dispatch(logout());
      useNavigate()('/login');
    }
    return Promise.reject(error);
  }
);

export default api;
