import React, { useState } from "react";
import {
  FiCopy,
  FiCheck,
  FiInfo,
  FiAlertTriangle,
  FiChevronDown,
  FiCheckCircle,
  FiDatabase,
  FiShield,
  FiServer,
  FiKey,
  FiCloud,
  FiChevronRight,
} from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";

const API_BASE_URL = "https://api.thirtyfirst.io/api";
const API_IP = "3.91.219.177";

const Step = ({ number, title, icon, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-6">
      <motion.div
        className="flex items-center cursor-pointer bg-[#242324] p-4 rounded-lg"
        onClick={() => setIsOpen(!isOpen)}
        whileHover={{ scale: 1.02 }}
      >
        <span className="mr-4 text-[#0056C8]">{icon}</span>
        <span className="mr-2 text-[#0056C8] font-bold">{number}.</span>
        <h3 className="text-xl font-semibold text-[#FFFFFF] flex-grow">
          {title}
        </h3>
        {isOpen ? (
          <FiChevronDown className="text-[#0056C8]" />
        ) : (
          <FiChevronRight className="text-[#0056C8]" />
        )}
      </motion.div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="mt-4 ml-12 pl-4 border-l-2 border-[#0056C8]"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const Tab = ({ icon, label, isActive, onClick }) => (
  <button
    className={`flex items-center px-4 py-2 rounded-t-lg ${
      isActive
        ? "bg-[#2F2F2F] text-[#FFFFFF]"
        : "bg-[#242324] text-[#9CA3AF] hover:bg-[#2A2A2A]"
    }`}
    onClick={onClick}
  >
    {icon}
    <span className="ml-2">{label}</span>
  </button>
);

const TabContent = ({ children, isActive }) => (
  <div
    className={`bg-[#2F2F2F] p-4 rounded-b-lg ${isActive ? "block" : "hidden"}`}
  >
    {children}
  </div>
);

const GettingStarted = () => {
  const [activeTab, setActiveTab] = useState("general");

  return (
    <div className="mb-12">
      <h2 className="text-2xl font-semibold mb-6 text-[#FFFFFF]">
        Getting Started
      </h2>

      <p className="mb-6">
        Welcome to ThirtyFirst API! Follow these steps to set up your database
        connection and start using our powerful database management
        capabilities:
      </p>

      <Step
        number={1}
        title="Link Your Database"
        icon={<FiDatabase size={24} />}
      >
        <p className="mb-4">
          Connect your MongoDB, PostgreSQL, or MySQL database to ThirtyFirst:
        </p>
        <ol className="list-decimal list-inside space-y-2 mb-4 text-[#E5E7EB]">
          <li>Go to "Databases" in your dashboard</li>
          <li>Click "Add New Database"</li>
          <li>Select your database type and enter connection details</li>
          <li>Test the connection and save</li>
        </ol>

        <div className="bg-[#0056C8] text-[#FFFFFF] p-4 rounded-md my-4">
          <h4 className="font-semibold mb-2">
            Important: Secure Database Configuration
          </h4>
          <p>
            You must configure your database to securely accept connections from
            ThirtyFirst's IP address: <b className="text-[#F59E0B]">{API_IP}</b>
          </p>
        </div>

        <div className="mt-6">
          <div className="flex space-x-2 mb-2">
            <Tab
              icon={<FiDatabase className="mr-1" />}
              label="General"
              isActive={activeTab === "general"}
              onClick={() => setActiveTab("general")}
            />
            <Tab
              icon={<FiServer className="mr-1" />}
              label="MongoDB"
              isActive={activeTab === "mongodb"}
              onClick={() => setActiveTab("mongodb")}
            />
            <Tab
              icon={<FiCloud className="mr-1" />}
              label="AWS"
              isActive={activeTab === "aws"}
              onClick={() => setActiveTab("aws")}
            />
          </div>

          <TabContent isActive={activeTab === "general"}>
            <h5 className="font-semibold mb-2">For Testing Environments:</h5>
            <ul className="list-disc list-inside space-y-1 mb-4">
              <li>
                Temporarily add ThirtyFirst's IP to your database's allowed IP
                list
              </li>
              <li>Use a separate, non-production database for testing</li>
              <li>Limit permissions for the test database user</li>
            </ul>

            <h5 className="font-semibold mb-2">For Production Environments:</h5>
            <ul className="list-disc list-inside space-y-1">
              <li>
                Use a firewall or security group to allow only ThirtyFirst's IP
              </li>
              <li>
                Create a dedicated database user for ThirtyFirst with minimal
                required permissions
              </li>
              <li>Enable and properly configure SSL/TLS for all connections</li>
            </ul>
          </TabContent>

          <TabContent isActive={activeTab === "mongodb"}>
            <h5 className="font-semibold mb-2">
              MongoDB-Specific Configuration:
            </h5>
            <ul className="list-disc list-inside space-y-1 mb-4">
              <li>
                In your MongoDB configuration file, do not set{" "}
                <code className="bg-[#242324] px-1 rounded">bindIp</code> to
                0.0.0.0
              </li>
              <li>
                Instead, use your server's private IP or localhost, and use a
                reverse proxy or SSH tunnel for remote access
              </li>
              <li>
                Enable authentication and create a dedicated user for
                ThirtyFirst
              </li>
            </ul>
            <p className="mb-2 text-[#F59E0B]">
              Example secure connection string:
            </p>
            <code className="block bg-[#242324] p-2 rounded">
              mongodb://thirtyfirst_user:password@host:port/database?ssl=true&authSource=admin
            </code>
          </TabContent>

          <TabContent isActive={activeTab === "aws"}>
            <h5 className="font-semibold mb-2">EC2-hosted databases:</h5>
            <ul className="list-disc list-inside space-y-1 mb-4">
              <li>
                Configure the EC2 security group to allow inbound traffic from
                ThirtyFirst's IP on the database port
              </li>
              <li>
                Use AWS Systems Manager Session Manager for secure remote access
                instead of opening SSH ports
              </li>
            </ul>

            <h5 className="font-semibold mb-2">RDS databases:</h5>
            <ul className="list-disc list-inside space-y-1 mb-4">
              <li>Modify the RDS security group to allow ThirtyFirst's IP</li>
              <li>Enable SSL connections and use AWS RDS certificates</li>
              <li>
                Consider using IAM database authentication for enhanced security
              </li>
            </ul>

            <h5 className="font-semibold mb-2">
              DocumentDB (MongoDB-compatible):
            </h5>
            <ul className="list-disc list-inside space-y-1">
              <li>
                Configure the cluster security group to allow ThirtyFirst's IP
              </li>
              <li>Use AWS-provided CA certificates for SSL connections</li>
              <li>Enable encryption in transit and at rest</li>
            </ul>
          </TabContent>
        </div>
      </Step>

      <Step
        number={2}
        title="Create a Permission Group"
        icon={<FiKey size={24} />}
      >
        <p className="mb-4">Set up a group to manage API access:</p>
        <ol className="list-decimal list-inside space-y-2 mb-4">
          <li>Navigate to "Permission Groups"</li>
          <li>Create a new group and link it to your database</li>
          <li>Generate a new API key for this group</li>
          <li>Save your API key securely - you won't see it again!</li>
        </ol>
        <p className="text-[#F59E0B] font-semibold">
          Important: Keep your API key confidential and secure.
        </p>
      </Step>

      <Step
        number={3}
        title="Configure Permissions"
        icon={<FiShield size={24} />}
      >
        <p className="mb-4">Define what your API can access:</p>
        <ol className="list-decimal list-inside space-y-2 mb-4">
          <li>Go to "Permissions" in your dashboard</li>
          <li>Add new permissions for your database</li>
          <li>Select tables/collections and fields to allow access to</li>
          <li>Set read, write, and delete permissions as needed</li>
        </ol>
      </Step>

      <Step
        number={4}
        title="Activate Your Setup"
        icon={<FiCheckCircle size={24} />}
      >
        <p className="mb-4">Enable your configured permissions:</p>
        <ol className="list-decimal list-inside space-y-2 mb-4">
          <li>Return to "Permission Groups"</li>
          <li>Edit your API access group</li>
          <li>Enable the permissions you created</li>
          <li>Save your changes</li>
        </ol>
      </Step>

      <Step number={5} title="Verify Your Setup" icon={<FiShield size={24} />}>
        <p className="mb-4">
          Before using the API in production, verify your setup:
        </p>
        <ol className="list-decimal list-inside space-y-2 mb-4">
          <li>
            Confirm that only ThirtyFirst's IP can access your database remotely
          </li>
          <li>Ensure SSL/TLS is properly configured and enforced</li>
          <li>
            Verify that the ThirtyFirst user has minimal required permissions
          </li>
          <li>Test a simple read query using the API to verify connectivity</li>
          <li>Monitor your database logs for any suspicious activity</li>
        </ol>
        <p className="text-[#F59E0B] font-semibold">
          Regularly review and update your security settings, and rotate
          credentials periodically.
        </p>
      </Step>

      <div className="mt-8 p-6 bg-[#0056C8] rounded-lg text-[#FFFFFF]">
        <h3 className="text-xl font-semibold mb-2">Ready to Go!</h3>
        <p>
          You're all set to start using the ThirtyFirst API securely. Check out
          the "API Endpoints" section to learn how to make queries and manage
          your data.
        </p>
      </div>
    </div>
  );
};

const CodeBlock = ({ children }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(children);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="relative bg-[#2F2F2F] p-4 rounded-md my-6 group">
      <pre className="text-[#E5E7EB] overflow-x-auto">
        <code>{children}</code>
      </pre>
      <motion.button
        onClick={copyToClipboard}
        className="absolute top-2 right-2 text-[#9CA3AF] hover:text-[#FFFFFF] opacity-0 group-hover:opacity-100 transition-opacity duration-200"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      >
        {copied ? <FiCheck /> : <FiCopy />}
      </motion.button>
    </div>
  );
};

const InfoBox = ({ children, type = "info" }) => {
  const bgColor = type === "warning" ? "bg-[#F59E0B]" : "bg-[#3B82F6]";
  const icon = type === "warning" ? <FiAlertTriangle /> : <FiInfo />;

  return (
    <motion.div
      className={`${bgColor} text-[#FFFFFF] p-4 rounded-md my-6 flex items-start`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <span className="mr-2 mt-1">{icon}</span>
      <div>{children}</div>
    </motion.div>
  );
};

const Section = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="mb-12 border-b border-[#2F2F2F] pb-8">
      <motion.h2
        className="text-2xl font-semibold mb-6 text-[#FFFFFF] flex items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {title}
        <motion.span className="ml-2" animate={{ rotate: isOpen ? 180 : 0 }}>
          <FiChevronDown />
        </motion.span>
      </motion.h2>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const ApiDocumentation = () => {
  const [selectedEndpoint, setSelectedEndpoint] = useState("mongodb");

  return (
    <div className="text-[#E5E7EB] p-8 min-h-screen max-w-4xl mx-auto">
      <GettingStarted />

      <Section title="Authentication">
        <p className="mb-4">
          All API requests require authentication using an API key. Include your
          API key in the{" "}
          <code className="bg-[#2F2F2F] px-1 py-0.5 rounded">x-api-key</code>{" "}
          header of your requests.
        </p>
        <CodeBlock>
          {`fetch('${API_BASE_URL}/your-endpoint', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': 'YOUR_API_KEY_HERE'
  },
  body: JSON.stringify({
    // Your request body here
  })
})`}
        </CodeBlock>
      </Section>

      <Section title="API Endpoints">
        <div className="flex space-x-4 mb-6">
          <motion.button
            className={`px-4 py-2 rounded ${
              selectedEndpoint === "mongodb"
                ? "bg-[#0056C8] text-[#FFFFFF]"
                : "bg-[#2F2F2F] text-[#E5E7EB]"
            }`}
            onClick={() => setSelectedEndpoint("mongodb")}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            MongoDB
          </motion.button>
          <motion.button
            className={`px-4 py-2 rounded ${
              selectedEndpoint === "sql"
                ? "bg-[#0056C8] text-[#FFFFFF]"
                : "bg-[#2F2F2F] text-[#E5E7EB]"
            }`}
            onClick={() => setSelectedEndpoint("sql")}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            SQL
          </motion.button>
        </div>

        <AnimatePresence mode="wait">
          {selectedEndpoint === "mongodb" ? (
            <motion.div
              key="mongodb"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3 }}
            >
              <h3 className="text-xl font-semibold mb-4 text-[#FFFFFF]">
                MongoDB Queries
              </h3>
              <p className="mb-4">
                Use this endpoint to execute MongoDB queries:
              </p>
              <CodeBlock>{`POST ${API_BASE_URL}/mongodb/query`}</CodeBlock>
              <h4 className="text-lg font-semibold mt-6 mb-4 text-[#FFFFFF]">
                Request Body
              </h4>
              <CodeBlock>
                {`{
  "operation": "find",
  "database": "your_database_name",
  "collection": "your_collection_name",
  "query": { "field": "value" },
  "options": { "limit": 10 }
}`}
              </CodeBlock>
              <p className="mt-4">
                Supported operations: find, findOne, insertOne, insertMany,
                updateOne, updateMany, deleteOne, deleteMany, aggregate,
                countDocuments, distinct
              </p>
              <InfoBox type="warning">
                Be cautious when using write operations (insert, update,
                delete). Always double-check your queries to prevent unintended
                data modifications.
              </InfoBox>
            </motion.div>
          ) : (
            <motion.div
              key="sql"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3 }}
            >
              <h3 className="text-xl font-semibold mb-4 text-[#FFFFFF]">
                SQL Queries
              </h3>
              <p className="mb-4">Use this endpoint to execute SQL queries:</p>
              <CodeBlock>{`POST ${API_BASE_URL}/sql/query`}</CodeBlock>
              <h4 className="text-lg font-semibold mt-6 mb-4 text-[#FFFFFF]">
                Request Body
              </h4>
              <CodeBlock>
                {`{
  "database": "your_database_name",
  "query": "SELECT * FROM users WHERE id = ?",
  "params": [1]
}`}
              </CodeBlock>
              <p className="mt-4">
                Supports both MySQL and PostgreSQL databases. The query type is
                automatically detected based on your database configuration.
              </p>
              <InfoBox>
                Use parameterized queries to prevent SQL injection attacks. The
                API will automatically handle the parameter substitution for
                you.
              </InfoBox>
            </motion.div>
          )}
        </AnimatePresence>
      </Section>

      <Section title="Error Handling">
        <p className="mb-4">
          The API uses standard HTTP status codes to indicate the success or
          failure of requests. Here are some common status codes you might
          encounter:
        </p>
        <ul className="list-disc list-inside mt-4 space-y-2">
          <li>
            <span className="font-semibold">200 OK</span>: The request was
            successful
          </li>
          <li>
            <span className="font-semibold">400 Bad Request</span>: The request
            was invalid or cannot be served
          </li>
          <li>
            <span className="font-semibold">401 Unauthorized</span>: The request
            requires authentication
          </li>
          <li>
            <span className="font-semibold">403 Forbidden</span>: The server
            understood the request but refuses to authorize it
          </li>
          <li>
            <span className="font-semibold">500 Internal Server Error</span>:
            The server encountered an unexpected condition
          </li>
        </ul>
        <InfoBox>
          Always check the response status and handle errors appropriately in
          your application.
        </InfoBox>
      </Section>

      <Section title="Rate Limiting">
        <p className="mb-4">
          To ensure fair usage and maintain performance, the API implements rate
          limiting. The current limits are:
        </p>
        <ul className="list-disc list-inside mt-4 space-y-2">
          <li>100 requests per minute per API key</li>
          <li>1000 requests per hour per API key</li>
        </ul>
        <p className="mt-4">
          If you exceed these limits, you'll receive a 429 Too Many Requests
          response. The response will include a Retry-After header indicating
          how long you should wait before making another request.
        </p>
      </Section>

      <Section title="Best Practices">
        <ul className="list-disc list-inside space-y-2">
          <li>Always use HTTPS for API requests to ensure data security</li>
          <li>Implement proper error handling in your application</li>
          <li>Use pagination for large result sets to improve performance</li>
          <li>Cache frequently accessed data when possible</li>
          <li>Monitor your API usage to stay within rate limits</li>
        </ul>
      </Section>

      <Section title="Need Help?">
        <p>
          If you encounter any issues or have questions about using the API,
          don't hesitate to reach out to our support team at{" "}
          <a
            href="mailto:cameronsaidock@gmail.com"
            className="text-[#3B82F6] hover:underline"
          >
            cameronsaidock@gmail.com
          </a>
          .
        </p>
      </Section>
    </div>
  );
};

export default ApiDocumentation;
