import React, { useState, useRef, useEffect } from "react";
import { FaKey, FaEllipsisV, FaLayerGroup, FaTrash } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import Checkbox from "../Checkbox";
import { BiLogoMongodb, BiLogoPostgresql } from "react-icons/bi";
import { TbBrandMysql } from "react-icons/tb";
import Modal from "../Modal";
import moment from "moment-timezone";
import Button from "../Button";

const PermissionGroupRow = ({
  group,
  onGenerateApiKey,
  onSelectGroup,
  selectedGroup,
  availablePermissions,
  selectedPermissions,
  handleAddPermissionToGroup,
  handleRemovePermissionFromGroup,
  onDeleteGroup, // Ensure this prop is here
  lastUsedTime,
}) => {
  const { group_name, group_id, database_name, database_type } = group;
  const isSelected = selectedGroup && selectedGroup.group_id === group_id;
  const [showPermissions, setShowPermissions] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let icon = "";

  switch (database_type) {
    case "postgres":
      icon = <BiLogoPostgresql className="text-md" />;
      break;
    case "mysql":
      icon = <TbBrandMysql className="text-md" />;
      break;
    case "mongodb":
      icon = <BiLogoMongodb className="text-md" />;
      break;
    default:
      break;
  }

  const togglePermissions = () => {
    if (isSelected) {
      setShowPermissions(!showPermissions);
    } else {
      onSelectGroup(group_id);
      setShowPermissions(true);
    }
  };

  const handleGenerateApiKey = () => {
    onGenerateApiKey(group_id).then((key) => {
      setApiKey(key);
    });
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
    setIsMenuOpen(false);
  };

  const handleDelete = () => {
    onDeleteGroup(group_id);
    setShowDeleteModal(false);
  };

  const formattedLastUsedTime = lastUsedTime
    ? `Last used on ${moment(lastUsedTime).format("MM/DD/YYYY")}`
    : "Never used";

  return (
    <div className="w-full flex flex-col">
      <div
        className={`w-full flex flex-row bg-[#111011] border border-[#2F2F2F] mt-4 rounded-lg hover:bg-[#242324] items-center p-4 shadow-md ${
          isSelected ? "bg-[#242324]" : ""
        }`}
      >
        <div className="w-3/5 flex flex-row gap-5 items-center">
          <div className="w-6 h-6 bg-[#0056B3] outline outline-[#0056B3] hover:bg-[#0049A6] outline-offset-2 rounded-full flex items-center justify-center">
            <FaLayerGroup className="text-white text-xs" />
          </div>
          <div className="flex flex-row gap-4 items-center">
            <p className="text-white font-geist font-normal">{group_name}</p>
            <span className="flex flex-row items-center justify-center gap-2 badge bg-transparent border border-[#0056B3] text-[#aaa] px-2 py-1 rounded-lg ml-3 text-xs">
              {icon} {database_name}
            </span>
          </div>
        </div>
        <div className="w-2/5 flex justify-end gap-2 items-center">
          <p className="text-[#898A8C] w-[50%] flex text-end justify-end font-geist font-light text-xs mr-4 mt-1">
            {formattedLastUsedTime}
          </p>
          <button
            onClick={handleGenerateApiKey}
            className="p-2 text-white rounded-lg w-[50%] flex items-center hover:text-[#aaa]"
          >
            <FaKey className="mr-2 text-xs" />
            <span>Generate API Key</span>
          </button>
          <div className="relative" ref={menuRef}>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={`p-2 text-white rounded-lg flex items-center hover:text-[#aaa] ${
                isMenuOpen ? "bg-[#2F2F2F]" : "bg-transparent"
              }`}
            >
              <FaEllipsisV />
            </button>
            <AnimatePresence>
              {isMenuOpen && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                  className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-[#1A1A1A] border border-[#3F3F3F] z-50"
                >
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <button
                      onClick={togglePermissions}
                      className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-[#2F2F2F] transition-colors duration-150 ease-in-out"
                      role="menuitem"
                    >
                      {showPermissions ? "Hide" : "Show"} Permissions
                    </button>
                    <button
                      onClick={handleDeleteClick}
                      className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-[#2F2F2F] transition-colors duration-150 ease-in-out"
                      role="menuitem"
                    >
                      <FaTrash className="inline-block mr-2 text-red-500" />{" "}
                      Delete
                    </button>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {apiKey && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-[#1A1A1A] border border-[#2F2F2F] rounded-lg p-4 mt-2 shadow-md"
          >
            <p className="text-white">
              <strong>API Key:</strong> {apiKey}
            </p>
            <p className="text-[#AAAAAA]">
              This is the only time you'll be able to see this key. Please save
              it securely.
            </p>
          </motion.div>
        )}
        {showPermissions && isSelected && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-[#1A1A1A] border border-[#2F2F2F] rounded-lg p-4 mt-2 shadow-md"
          >
            <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
              <div className="w-full lg:w-1/2 flex flex-col">
                <h3 className="text-md text-white font-medium mb-2">
                  Available Permissions
                </h3>
                <div className="flex flex-wrap">
                  {availablePermissions.length === 0 ? (
                    <p className="text-[#AAAAAA] italic font-light text-sm">
                      No permissions available
                    </p>
                  ) : (
                    availablePermissions.map((permission) => (
                      <label
                        key={permission.permission_id}
                        className="p-2 bg-[#232426] border border-[#2F2F2F] rounded-lg m-1 flex cursor-pointer items-center"
                      >
                        <Checkbox
                          label={permission.permission_name}
                          value={permission.permission_id}
                          checked={selectedPermissions.some(
                            (perm) =>
                              perm.permission_id === permission.permission_id
                          )}
                          onChange={() =>
                            handleAddPermissionToGroup(permission.permission_id)
                          }
                        />
                      </label>
                    ))
                  )}
                </div>
              </div>
              <div className="w-full lg:w-1/2 flex flex-col">
                <h3 className="text-md text-white font-medium mb-2">
                  Enabled Permissions
                </h3>
                <div className="flex flex-wrap">
                  {selectedPermissions.length === 0 ? (
                    <p className="text-[#AAAAAA] italic font-light text-sm">
                      No permissions enabled
                    </p>
                  ) : (
                    selectedPermissions.map((permission) => (
                      <label
                        key={permission.permission_id}
                        className="p-2 bg-[#232426] border border-[#2F2F2F] rounded-lg m-1 flex items-center cursor-pointer"
                      >
                        <Checkbox
                          label={permission.permission_name}
                          value={permission.permission_id}
                          checked
                          onChange={() =>
                            handleRemovePermissionFromGroup(
                              permission.permission_id
                            )
                          }
                        />
                      </label>
                    ))
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        )}
        {showDeleteModal && (
          <Modal
            setShowModal={setShowDeleteModal}
            title="Confirm Delete Permission Group"
            subtitle="Are you sure you want to delete this permission group?"
            icon={<FaTrash className="text-red-500" />}
          >
            <Button onClick={handleDelete} variation="primary">
              Confirm
            </Button>
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PermissionGroupRow;
