import React, { useEffect, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import moment from "moment-timezone";
import axios from "axios";

const locationCache = new Map();

const fetchLocationFromIP = async (ip) => {
  if (locationCache.has(ip)) {
    return locationCache.get(ip);
  }

  try {
    const response = await axios.get(`http://ip-api.com/json/${ip}`);
    const data = response.data;

    if (data.status === "success") {
      const location = `${data.city}, ${data.regionName}, ${data.country}`;
      locationCache.set(ip, location);
      return location;
    } else {
      return "";
    }
  } catch (error) {
    console.error("Error fetching location:", error);
    return "";
  }
};

const getAnomalyColor = (score) => {
  if (score >= 0.8) return "bg-red-500 outline-red-500";
  if (score >= 0.6) return "bg-orange-500 outline-orange-500";
  return "bg-yellow-500 outline-yellow-500";
};

const SuspiciousQueryItem = ({ activity }) => {
  const [userLocation, setUserLocation] = useState("");

  useEffect(() => {
    const getUserLocation = async () => {
      const location = await fetchLocationFromIP(activity.user_ip);
      setUserLocation(location);
    };

    getUserLocation();
  }, [activity.user_ip]);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formattedTime = moment(activity.execution_time)
    .local()
    .format("MMM D, YYYY h:mm:ss A");

  return (
    <div className="w-full flex flex-row items-center bg-[#111011] border border-[#2F2F2F] mt-4 rounded-lg p-4 shadow-md">
      <div
        className={`min-w-12 min-h-12 max-w-12 max-h-12 w-12 h-12 rounded-full outline outline-offset-2 ${getAnomalyColor(
          activity.anomaly_score
        )} flex items-center justify-center mr-4 shadow-lg`}
      >
        <span className="text-white text-xl font-bold">
          <FaExclamationTriangle />
        </span>
      </div>
      <div className="flex w-full flex-col justify-center">
        <p className="text-white font-semibold">{formattedTime}</p>
        <div className="flex w-full flex-row items-center justify-between">
          <p className="text-gray-400 text-xs truncate w-64 mt-[3px]">
            Query:{" "}
            <span className="text-gray-300 text-sm">{activity.query}</span>
          </p>
          <div className="text-gray-300 w-full flex items-center justify-end gap-1 text-sm">
            <span className="text-gray-400 text-xs">IP: </span>{" "}
            {activity.user_ip}
          </div>
        </div>
        <div className="w-full flex flex-row items-center justify-between">
          <p className="text-gray-400 text-xs truncate w-64 mt-[3px]">
            Anomaly Score:{" "}
            <span className="text-gray-300 text-sm">
              {activity.anomaly_score.toFixed(2)}
            </span>
          </p>
          {userLocation && (
            <div className="text-gray-300 w-full text-sm text-end">
              <span className="text-gray-400 text-xs"></span> {userLocation}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuspiciousQueryItem;
