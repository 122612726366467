import React from "react";
import { motion } from "framer-motion";
import { FiDatabase, FiShield, FiLock, FiKey } from "react-icons/fi";

const HeroVisual = () => {
  const orbitRadius = 120;
  const centerX = 150;
  const centerY = 150;

  return (
    <div className="relative w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mx-auto">
      <svg
        className="w-full h-full"
        viewBox="0 0 300 300"
        preserveAspectRatio="xMidYMid meet"
      >
        {/* Background circular grid */}
        <circle
          cx={centerX}
          cy={centerY}
          r={140}
          fill="none"
          stroke="#2F2F2F"
          strokeWidth="0.5"
        />
        <circle
          cx={centerX}
          cy={centerY}
          r={100}
          fill="none"
          stroke="#2F2F2F"
          strokeWidth="0.5"
        />
        <circle
          cx={centerX}
          cy={centerY}
          r={60}
          fill="none"
          stroke="#2F2F2F"
          strokeWidth="0.5"
        />

        {/* Orbiting path */}
        <circle
          cx={centerX}
          cy={centerY}
          r={orbitRadius}
          fill="none"
          stroke="#0056C8"
          strokeWidth="1"
          strokeDasharray="4 4"
        />

        {/* Data flow lines */}
        {[0, 1, 2].map((index) => {
          const angle = (index * 2 * Math.PI) / 3;
          const endX = centerX + orbitRadius * Math.cos(angle);
          const endY = centerY + orbitRadius * Math.sin(angle);
          return (
            <motion.path
              key={`flow-${index}`}
              d={`M${centerX},${centerY} Q${centerX + 50 * Math.cos(angle)},${
                centerY + 50 * Math.sin(angle)
              } ${endX},${endY}`}
              fill="none"
              stroke="#0056C8"
              strokeWidth="2"
              initial={{ pathLength: 0, opacity: 0 }}
              animate={{ pathLength: 1, opacity: 1 }}
              exit={{ pathLength: 1, opacity: 0 }}
              transition={{
                duration: 4,
                delay: index * 1,
                repeat: Infinity,
                repeatDelay: 1,
              }}
            />
          );
        })}

        {/* Orbiting elements */}
        {[FiShield, FiLock, FiKey].map((Icon, index) => {
          const angle = (index * 2 * Math.PI) / 3;
          const x = centerX + orbitRadius * Math.cos(angle);
          const y = centerY + orbitRadius * Math.sin(angle);
          return (
            <motion.g
              key={index}
              animate={{
                y: [0, -10, 0],
              }}
              transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
            >
              <circle cx={x} cy={y} r={20} fill="#2F2F2F" />
              <foreignObject x={x - 10} y={y - 10} width="20" height="20">
                <div className="flex items-center justify-center w-full h-full">
                  <Icon className="text-3xl text-[#3B82F6]" />
                </div>
              </foreignObject>
            </motion.g>
          );
        })}

        {/* Central database icon */}
        <motion.g
          animate={{ scale: [1, 1.1, 1] }}
          transition={{ duration: 3, repeat: Infinity }}
        >
          <circle cx={centerX} cy={centerY} r={40} fill="#242324" />
          <foreignObject
            x={centerX - 20}
            y={centerY - 20}
            width="40"
            height="40"
          >
            <div className="flex items-center justify-center w-full h-full">
              <FiDatabase className="text-6xl text-[#0056C8]" />
            </div>
          </foreignObject>
        </motion.g>

        {/* Pulsating security ring */}
        <motion.circle
          cx={centerX}
          cy={centerY}
          r={50}
          fill="none"
          stroke="#10B981"
          strokeWidth="2"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1.2, opacity: 0.5 }}
          transition={{ duration: 2, repeat: Infinity }}
        />
      </svg>

      {/* Floating data particles */}
      {[...Array(10)].map((_, index) => (
        <motion.div
          key={`particle-${index}`}
          className="absolute w-1 h-1 bg-[#3B82F6] rounded-full"
          style={{
            top: Math.random() * 300,
            left: Math.random() * 300,
          }}
          animate={{
            y: [0, -20, 0],
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: 2 + Math.random() * 2,
            repeat: Infinity,
            delay: Math.random() * 2,
          }}
        />
      ))}
    </div>
  );
};

export default HeroVisual;
