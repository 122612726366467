import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  FaHome,
  FaBuilding,
  FaCode,
  FaCogs,
  FaDatabase,
  FaKey,
  FaUsers,
  FaLayerGroup,
  FaBook,
  FaBell,
  FaExclamationTriangle,
} from "react-icons/fa";
import ThirtyFirst from "../../assets/ThirtyFirst.svg";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";
import generateUserUrlSegment from "../../utils/urlSegment";
import Button from "../Button";
import ActiveBreachAlert from "./ActiveBreachAlert";

const Sidebar = ({
  userRole,
  selectedEnterprise,
  selectedTab,
  onSelectTab,
  setSelectedEnterprise,
  databases,
}) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [name, setName] = useState("");

  useEffect(() => {
    if (user) {
      setName(generateUserUrlSegment(user.email));
    }
  }, [user]);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const generalTabs = [
    {
      name: "Organizations",
      tab: "organizations",
      icon: FaBuilding,
      adminOnly: false,
    },
    // { name: "Settings", tab: "settings", icon: FaCogs },
  ];

  const organizationTabs = [
    { name: "Dashboard", tab: "dashboard", icon: FaHome, adminOnly: false },
    { name: "Databases", tab: "databases", icon: FaDatabase, adminOnly: true },
    { name: "Users", tab: "users", icon: FaUsers, adminOnly: true },
    { name: "Permissions", tab: "permissions", icon: FaKey, adminOnly: true },
    {
      name: "Permission Groups",
      tab: "permissionGroups",
      icon: FaLayerGroup,
      adminOnly: true,
    },
    { name: "Logs", tab: "logs", icon: FaBook, adminOnly: false },
    {
      name: "Breach Control",
      tab: "breachControl",
      icon: FaBell,
      adminOnly: true,
    },
    {
      name: "Documentation",
      tab: "apiDocumentation",
      icon: FaCode,
      adminOnly: false,
    },
    // { name: "Settings", tab: "enterprise_settings", icon: FaCogs },
  ];

  const tabsToRender = selectedEnterprise ? organizationTabs : generalTabs;

  const handleTabClick = (tab) => {
    onSelectTab(tab);
  };

  const handleNameClick = () => {
    if (selectedEnterprise) {
      navigate(`/dashboard/${generateUserUrlSegment(user.email)}`);
      onSelectTab("organizations");
      setSelectedEnterprise(null);
    }
  };

  const activeBreaches = databases?.filter(
    (db) => db.access_cutoff || db.isolation_mode
  );

  return (
    <div className="w-1/6 min-w-[300px] min-h-full h-full bg-[#111011] border-r border-[#242324] text-white flex flex-col justify-between overflow-hidden">
      <div className="w-full px-4 pt-8 pb-4 flex items-center justify-start">
        <img src={ThirtyFirst} alt="Logo" className="w-8 h-8 object-contain" />
        <div className="flex flex-row gap-2 font-dm font-medium text-md text-white items-center">
          <h2
            onClick={handleNameClick}
            className={`hover:bg-[#232323] truncate ${
              selectedEnterprise ? "max-w-[50%]" : "w-auto"
            } text-md rounded-lg p-2 py-1 cursor-pointer`}
          >
            {name.toLowerCase()}
          </h2>
          {selectedEnterprise && (
            <>
              <h2 className="font-bold text-md flex-shrink-0">/</h2>
              <h2 className="rounded-lg truncate text-md max-w-[50%] p-2 py-1">
                {selectedEnterprise}
              </h2>
            </>
          )}
        </div>
      </div>
      <div className="w-full h-full pl-8 py-4 flex flex-col gap-1">
        {tabsToRender
          .filter(
            (tab) =>
              !tab.adminOnly || userRole === "admin" || userRole === "owner"
          )
          .map((tab) => (
            <div
              key={tab.tab}
              className={`font-dm text-sm w-full flex items-center gap-4 font-medium cursor-pointer py-3 px-4 rounded-l-md ${
                selectedTab === tab.tab
                  ? "bg-[#232323] border-r-2 border-[#1473F5]"
                  : "hover:bg-[#232323]"
              }`}
              onClick={() => handleTabClick(tab.tab)}
            >
              <tab.icon
                className={`text-md ${
                  selectedTab === tab.tab ? "text-[#007BFF]" : ""
                }`}
              />
              {tab.name}
            </div>
          ))}
      </div>

      <div className="w-full py-8 px-4 flex flex-col gap-2 items-center justify-center">
        <ActiveBreachAlert activeBreaches={activeBreaches} />
        <Button onClick={handleLogout} variation="secondary">
          Logout
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
