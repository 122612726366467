import React from "react";
import { ReactComponent as SetupBackground } from "../assets/SetupBackground.svg";
import Login from "../components/Auth/Login";
import Signup from "../components/Auth/Signup";
import { useNavigate } from "react-router-dom";
import { FiUser, FiKey, FiShield, FiCheckCircle, FiLock } from "react-icons/fi";

const Node = ({ x, y, icon: Icon, color, size = 30, pulse = false }) => (
  <g transform={`translate(${x}, ${y})`}>
    <circle r={size / 2} fill="#242324" stroke={color} strokeWidth="2" />
    {pulse && (
      <circle
        r={size / 2}
        fill="none"
        stroke={color}
        strokeWidth="2"
        opacity="0.5"
      >
        <animate
          attributeName="r"
          from={size / 2}
          to={size}
          dur="1.5s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="0.5"
          to="0"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </circle>
    )}
    <foreignObject
      x={-size / 4}
      y={-size / 4}
      width={size / 2}
      height={size / 2}
    >
      <div className="w-full h-full flex items-center justify-center">
        <Icon className="w-full h-full" color={color} />
      </div>
    </foreignObject>
  </g>
);

const DataPath = ({ id, d, color, duration }) => (
  <g>
    <path
      id={id}
      d={d}
      stroke={color}
      strokeWidth="1"
      strokeOpacity="0.2"
      fill="none"
    />
    {[...Array(5)].map((_, i) => (
      <circle key={i} r="3" fill={color}>
        <animateMotion
          dur={`${duration}s`}
          repeatCount="indefinite"
          rotate="auto"
          begin={`-${(i * duration) / 5}s`}
        >
          <mpath href={`#${id}`} />
        </animateMotion>
      </circle>
    ))}
  </g>
);

const AuthAnimation = () => (
  <div className="w-full h-24 sm:h-40 md:h-40">
    <svg
      className="w-full h-full"
      viewBox="0 0 1500 200"
      preserveAspectRatio="xMidYMid meet"
    >
      {/* All paths are rendered first */}
      <DataPath
        id="path1"
        d="M0,120 Q375,60 750,120 T1500,120"
        color="#0056C8"
        duration="30"
      />
      <DataPath
        id="path2"
        d="M0,80 Q375,140 750,80 T1500,80"
        color="#F59E0B"
        duration="25"
      />

      {/* Additional auth-related paths */}
      <g>
        <path
          d="M0,100 Q187.5,80 375,100"
          stroke="#0056C8"
          strokeWidth="1"
          fill="none"
          strokeDasharray="5,5"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="20"
            dur="2s"
            repeatCount="indefinite"
          />
        </path>
        <circle r="4" fill="#0056C8">
          <animateMotion
            path="M0,100 Q187.5,80 375,100"
            dur="5s"
            repeatCount="indefinite"
          />
        </circle>
      </g>
      <g>
        <path
          d="M750,100 Q937.5,120 1125,100"
          stroke="#F59E0B"
          strokeWidth="1"
          fill="none"
          strokeDasharray="5,5"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="0"
            to="20"
            dur="2s"
            repeatCount="indefinite"
          />
        </path>
        <circle r="4" fill="#F59E0B">
          <animateMotion
            path="M750,100 Q937.5,120 1125,100"
            dur="5s"
            repeatCount="indefinite"
          />
        </circle>
      </g>

      {/* All nodes are rendered last, ensuring they appear on top */}
      <Node x={0} y={100} icon={FiUser} color="#0056C8" size={40} pulse />
      <Node x={375} y={100} icon={FiKey} color="#3B82F6" size={30} />
      <Node x={750} y={100} icon={FiShield} color="#F59E0B" size={40} pulse />
      <Node x={1125} y={100} icon={FiCheckCircle} color="#10B981" size={30} />
      <Node x={1500} y={100} icon={FiLock} color="#3B82F6" size={40} pulse />
    </svg>
  </div>
);

function Auth({ type }) {
  const navigate = useNavigate();

  return (
    <div className="relative flex flex-col items-center justify-between w-screen h-screen bg-[#0F0E0F] overflow-hidden">
      <SetupBackground className="absolute top-0 left-0 w-full pointer-events-none" />

      <div className="flex-grow flex flex-col items-center justify-center w-full max-w-md px-4 py-8 z-10">
        <h3
          onClick={() => navigate("/")}
          className="font-dm text-lg font-medium text-[#0056B3] hover:underline hover:underline-offset-2 hover:underline-[#0056B3]"
        >
          THIRTYFIRST
        </h3>
        <h1 className="font-clash font-medium text-center text-[#ffffff] text-3xl sm:text-4xl md:text-5xl mt-2 mb-6">
          {type === "login" ? "Login" : "Sign Up"}
        </h1>

        {type === "login" ? <Login /> : <Signup />}
      </div>

      <div className="w-full pointer-events-none">
        <AuthAnimation />
      </div>
    </div>
  );
}

export default Auth;
