import React from "react";
import PropTypes from "prop-types";

const Select = ({
  label,
  value,
  onChange,
  options,
  placeholder,
  disabled,
  error,
}) => {
  return (
    <div className={`mb-4 ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}>
      <label className="block text-[#AAAAAA] text-sm font-medium font-dm mb-2">
        {label}
      </label>
      <div className="relative">
        <select
          value={value}
          onChange={onChange}
          disabled={disabled}
          className={`font-dm shadow appearance-none border rounded-lg w-full py-3 px-3 text-[#AAAAAA] leading-tight focus:outline-none focus:shadow-outline ${
            error ? "border-red-500" : "border-[#37393C]"
          } ${disabled ? "cursor-not-allowed bg-[#1C1D1F]" : "bg-[#232426]"}`}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {error && <p className="text-red-500 text-xs italic mt-2">{error}</p>}
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

export default Select;
