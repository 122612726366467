import React, { useEffect, useState, useRef } from "react";
import { FaDatabase, FaUser, FaTrash, FaEllipsisV } from "react-icons/fa";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { motion, AnimatePresence } from "framer-motion";
import Modal from "../Modal";
import Button from "../Button";
import Input from "../Input";

const generateDataFromLogs = (logs) => {
  const data = [];
  const now = new Date();
  const timeIntervals = {};

  // Initialize time intervals for the past 24 hours
  for (let i = 0; i < 24; i++) {
    const time = new Date(now.getTime() - i * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 13); // Get the ISO string and slice to the hour
    timeIntervals[time] = 0;
    data.push({ time, queries: 0 });
  }

  // Process logs to count queries per hour
  logs.forEach((log) => {
    const executionTime = new Date(log.execution_time)
      .toISOString()
      .slice(0, 13); // Get the ISO string and slice to the hour
    if (timeIntervals[executionTime] !== undefined) {
      timeIntervals[executionTime] += 1;
    }
  });

  // Populate data with query counts
  data.forEach((item) => {
    item.queries = timeIntervals[item.time];
  });

  return data.reverse();
};

function OrganizationCard({ enterprise, onClick, onDelete }) {
  const { enterprise_name, role, database_count, logs } = enterprise;
  const [data, setData] = useState([]);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });
  const [showModal, setShowModal] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [inputCode, setInputCode] = useState("");
  const cardRef = useRef(null);

  useEffect(() => {
    setData(generateDataFromLogs(logs));
  }, [logs]);

  useEffect(() => {
    if (showModal) {
      setConfirmationCode(
        Math.random().toString(36).substr(2, 6).toUpperCase()
      );
      setInputCode("");
    }
  }, [showModal]);

  const avgQueriesPerHour = (
    data.reduce((acc, curr) => acc + parseFloat(curr.queries), 0) / data.length
  ).toFixed(2);

  const maxQueries = Math.max(...data.map((item) => parseFloat(item.queries)));

  const handleCardClick = (e) => {
    if (!showContextMenu) {
      onClick(e);
    }
  };

  const handleContextMenu = (e) => {
    if (role !== "owner") return;
    e.preventDefault();
    const rect = cardRef.current.getBoundingClientRect();
    setContextMenuPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
    setShowContextMenu(true);
  };

  const handleEllipsisClick = (e) => {
    e.stopPropagation();
    const rect = cardRef.current.getBoundingClientRect();
    setContextMenuPosition({ x: rect.width - 160, y: 40 });
    setShowContextMenu(true);
  };

  const handleDeleteClick = () => {
    setShowContextMenu(false);
    setShowModal(true);
  };

  const handleDeleteConfirm = () => {
    if (inputCode === confirmationCode) {
      onDelete(enterprise.enterprise_id);
      setShowModal(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        setShowContextMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <motion.div
        ref={cardRef}
        onClick={handleCardClick}
        onContextMenu={handleContextMenu}
        className="w-[325px] py-4 bg-[#111011] border-[#2F2F2F] border rounded-lg overflow-hidden cursor-pointer relative group"
        whileHover={{ scale: 1.02 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <div className="w-full h-full flex flex-col">
          <div className="w-full cursor-pointer">
            <ResponsiveContainer width="100%" height={100}>
              <LineChart
                margin={{ top: 4, right: 0, bottom: 4, left: 0 }}
                data={data}
                style={{ cursor: "pointer" }}
              >
                <XAxis dataKey="time" hide />
                <YAxis domain={[0, maxQueries + 10]} hide />
                <Line
                  type="monotone"
                  strokeWidth={1.5}
                  dataKey="queries"
                  stroke="#0056B3"
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
            <p className="text-[#aaaaaa] font-dm font-light italic text-xs py-3 w-full text-end px-3">
              <b className="text-white font-medium not-italic">
                {avgQueriesPerHour}
              </b>{" "}
              queries / hour
            </p>
          </div>
          <div className="w-full px-6 flex flex-col gap-2 pb-4">
            <h1 className="font-dm text-white font-medium text-lg">
              {enterprise_name}
            </h1>
            <div className="w-full flex flex-row gap-6 pt-2">
              <div className="flex flex-row gap-2 items-center">
                <FaUser className="text-[#0056C8] text-xs" />
                <p className="text-gray-200 font-extralight font-dm text-sm">
                  {role[0].toUpperCase() + role.slice(1)}
                </p>
              </div>
              <div className="flex flex-row gap-2 items-center">
                <FaDatabase className="text-[#0056C8] text-xs" />
                <p className="text-gray-200 font-extralight font-dm text-sm inline-block inherit">
                  {database_count}{" "}
                  <span className="text-[#aaaaaa] font-dm font-light text-xs w-full text-end ml-1">
                    {database_count === 1 ? "database" : "databases"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        {role === "owner" && (
          <motion.div
            className="absolute top-2 right-2 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
            whileHover={{ scale: 1.2 }}
          >
            <FaEllipsisV
              onClick={handleEllipsisClick}
              className="cursor-pointer"
            />
          </motion.div>
        )}

        <AnimatePresence>
          {showContextMenu && (
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.1 }}
              className="absolute bg-[#1A1A1A] border border-[#2F2F2F] rounded-md shadow-lg z-50"
              style={{
                top: contextMenuPosition.y,
                left: contextMenuPosition.x,
              }}
            >
              <button
                onClick={handleDeleteClick}
                className="flex items-center w-full text-left px-4 py-2 text-sm text-white hover:bg-[#2F2F2F] transition-colors duration-150 ease-in-out"
              >
                <FaTrash className="mr-2 text-red-500" /> Delete Organization
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      {showModal && (
        <Modal
          setShowModal={setShowModal}
          title={`Confirm ${enterprise_name} Deletion`}
          subtitle="Type the code to proceed"
          icon={<FaTrash />}
        >
          <div className="flex flex-col w-full h-full pt-2 items-center justify-start gap-4">
            <h2 className="text-blue-500 font-semibold text-lg mb-2">
              {confirmationCode}
            </h2>
            <Input
              type="text"
              label="Confirmation code"
              placeholder="Enter the confirmation code"
              value={inputCode}
              onChange={(e) => setInputCode(e.target.value)}
            />
            <div className="flex flex-col w-full mt-2 mb-4">
            <b className="text-white font-semibold mb-3 mt-2 font-dm">Warning</b>
              <p className="text-[#AAAAAA] text-sm font-medium font-dm mb-2"> This action
                will delete everything associated with the organization and may
                disrupt database interactions if not handled properly.{" "}
                <span className="text-red-500">
                  This action cannot be undone.
                </span>
              </p>
            </div>
            <div className="flex gap-4 w-full">
              <Button
                onClick={handleDeleteConfirm}
                disabled={inputCode !== confirmationCode}
                variation="primary"
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default OrganizationCard;
