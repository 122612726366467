import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

const Button = ({ onClick, disabled = false, loading = false, variation = 'primary', children }) => {
  let background, hoverBackground, activeBackground, border;

  switch (variation) {
    case 'primary':
      background = '#0056B3';
      hoverBackground = '#004494';
      activeBackground = '#003377';
      border = '#0062CC';
      break;
    case 'secondary':
      background = '#232426';
      hoverBackground = '#1c1c1e';
      activeBackground = '#161617';
      border = '#3B3C40';
      break;
    case 'alert':
      background = '#D32F2F';
      hoverBackground = '#B71C1C';
      activeBackground = '#C62828';
      border = '#E53935';
      break;
    default:
      background = '#232426';
      hoverBackground = '#1c1c1e';
      activeBackground = '#161617';
      border = '#3B3C40';
  }

  return (
    <button
      className={`w-full text-sm flex items-center justify-center font-dm py-2 px-4 rounded-lg text-white focus:outline-none focus:shadow-outline ${
        disabled || loading ? 'bg-gray-600 cursor-not-allowed' : ''
      }`}
      onClick={onClick}
      disabled={disabled || loading}
      style={{
        backgroundColor: disabled || loading ? '#666' : background,
        borderColor: border,
      }}
      onMouseOver={(e) => {
        if (!disabled && !loading) e.currentTarget.style.backgroundColor = hoverBackground;
      }}
      onMouseOut={(e) => {
        if (!disabled && !loading) e.currentTarget.style.backgroundColor = background;
      }}
      onMouseDown={(e) => {
        if (!disabled && !loading) e.currentTarget.style.backgroundColor = activeBackground;
      }}
      onMouseUp={(e) => {
        if (!disabled && !loading) e.currentTarget.style.backgroundColor = hoverBackground;
      }}
    >
      {loading ? <ClipLoader size={20} color="#fff" /> : children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  variation: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node.isRequired,
};

export default Button;
