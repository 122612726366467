import React from "react";

const Toggle = ({ checked, onChange, disabled }) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <div
        className={`w-11 h-6 rounded-full peer peer-focus:ring-4 ${
          checked
            ? "bg-[#0056C8] peer-focus:ring-[#0056C8]"
            : "bg-gray-600 peer-focus:ring-gray-300"
        } ${disabled ? "cursor-not-allowed opacity-50" : ""}`}
      >
        <div
          className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition ${
            checked ? "transform translate-x-5" : ""
          }`}
        ></div>
      </div>
    </label>
  );
};

export default Toggle;
