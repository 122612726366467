import React from "react";
import { motion } from "framer-motion";
import { FiCheck, FiArrowRight, FiServer } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const AdvantageCard = ({ activeNode, dataNodes }) => {
  const node = dataNodes[activeNode];

  const navigate = useNavigate();

  return (
    <div className="w-full">
      <h2 style={{ color: node.color }} className={`font-geist text-2xl mb-2 font-bold`}>
        {node.title}
      </h2>
      <p className="font-dm text-[#E5E7EB] mb-8 text-lg leading-relaxed">
        {node.description}
      </p>
      <div>
        <h3 className="font-geist text-md font-semibold text-white mb-4 flex items-center">
          Impact for Your Team
        </h3>
        <ul className="space-y-3">
          {node.impact.map((item, index) => (
            <li key={index} className="flex items-start">
              <FiCheck style={{ color: node.color }} className="mt-1 mr-2 flex-shrink-0" />
              <span className="text-[#E5E7EB]">{item}</span>
            </li>
          ))}
        </ul>
      </div>
      <button
      style={{ backgroundColor: node.color }}
        onClick={() => navigate("/signup")}
        className="text-white px-6 mt-12 py-3 rounded-full font-semibold transition-all flex items-center group"
      >
        <span className="mr-2">Get Started</span>
        <FiArrowRight className="transition-transform group-hover:translate-x-1" />
      </button>
    </div>
  );
};

export default AdvantageCard;
