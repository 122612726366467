import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ThirtyFirst from "../../assets/ThirtyFirst.svg";
import { useNavigate } from "react-router-dom";

// Header Component
const Header = ({ setActiveSection }) => {
  const [isHovered, setIsHovered] = useState(false);

  const navigate = useNavigate()

  return (
    <div className="fixed z-50 px-8 h-16 top-0 w-full flex justify-between items-center bg-[#0F0E0F] bg-opacity-80 backdrop-blur-md">
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className="flex items-center relative"
      >
        <img src={ThirtyFirst} alt="Logo" className="w-8 h-8 object-contain" />
        <h1 className="ml-2 font-dm relative font-medium text-[#ffffff] text-2xl cursor-pointer">
          THIRTYFIRST
          {isHovered && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="absolute left-0 mt-2 w-80 p-4 bg-[#111011] border-[#2F2F2F] border rounded-lg text-white shadow-md"
            >
              <p className="text-lg font-semibold italic">ThirtyFirst</p>
              <p className="text-sm italic text-[#aaaaaa]">noun</p>
              <hr className="my-2 border-t border-[#2F2F2F]" />
              <p className="text-sm pt-4 pb-4">
                <span className="font-semibold">1.</span> Named for our commitment to making digital rights the 31st right.
              </p>
            </motion.div>
          )}
        </h1>
      </div>

      <div className="flex items-center">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate("/login")}
          className="text-white bg-[#0056C8] hover:bg-[#0056B3] px-4 py-2 rounded-full transition-colors duration-300 shadow-lg"
        >
          Login
        </motion.button>
      </div>
    </div>
  );
};

export default Header;
