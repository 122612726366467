import React from "react";
import PropTypes from "prop-types";

const Textarea = ({
  label,
  value,
  onChange,
  placeholder,
  disabled,
  error,
  style,
}) => {
  return (
    <div className={`w-full ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}>
      {label && (
        <label className="block text-[#AAAAAA] text-sm font-medium font-dm mb-2">
          {label}
        </label>
      )}
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        style={style}
        className={`font-dm shadow appearance-none border rounded-lg w-full py-3 px-3 text-[#E5E7EB] leading-tight focus:outline-none focus:shadow-outline ${
          error ? "border-[#EF4444]" : "border-[#2F2F2F]"
        } ${disabled ? "cursor-not-allowed bg-[#2F2F2F]" : "bg-[#232426]"}`}
      />
      {error && <p className="text-[#EF4444] text-xs italic mt-2">{error}</p>}
    </div>
  );
};

Textarea.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  style: PropTypes.object,
};

export default Textarea;
