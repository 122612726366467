import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import LogRow from "../Dashboard/LogRow";

const AuditTrailDashboard = () => {
  const [openLogId, setOpenLogId] = useState(null);

  const mockLogs = [
    {
      id: 1,
      group_name: "Admin Group",
      query: 'SELECT * FROM users WHERE role = "admin"',
      execution_time: "2024-06-28T10:30:00Z",
      status: "success",
      user_ip: "192.168.1.100",
      error_message: null,
      anomaly_score: 0.1,
    },
    {
      id: 2,
      group_name: "Analytics Team",
      query:
        "UPDATE user_data SET last_login = CURRENT_TIMESTAMP WHERE user_id = 12345",
      execution_time: "2024-06-28T11:15:00Z",
      status: "error",
      user_ip: "192.168.1.101",
      error_message: "Permission denied",
      anomaly_score: 0.7,
    },
    {
      id: 3,
      group_name: "Marketing Team",
      query: 'SELECT COUNT(*) FROM orders WHERE date > "2024-06-01"',
      execution_time: "2024-06-28T12:00:00Z",
      status: "success",
      user_ip: "192.168.1.102",
      error_message: null,
      anomaly_score: 0.2,
    },
  ];

  const handleLogClick = (logId) => {
    setOpenLogId(openLogId === logId ? null : logId);
  };

  return (
    <div className="text-text-primary min-w-[800px] flex flex-col">
      <div className="w-full flex flex-row justify-between bg-[#111011] text-[#AAAAAA] font-dm font-medium rounded-lg">
        <h1 className="w-[20%] py-3 px-6 hover:text-[#BBBBBD] transition-all duration-200">
          Group
        </h1>
        <h1 className="w-[40%] py-3 hover:text-[#BBBBBD] transition-all duration-200">
          Query
        </h1>
        <h1 className="w-[25%] py-3 hover:text-[#BBBBBD] transition-all duration-200">
          Execution Time
        </h1>
        <h1 className="w-[15%] py-3 hover:text-[#BBBBBD] transition-all duration-200">
          Status
        </h1>
        <div className="p-2 w-[5%]" />
      </div>
      <div className="space-y-2">
        {mockLogs.map((log) => (
          <LogRow
            key={log.id}
            log={log}
            onClick={() => handleLogClick(log.id)}
            isOpen={openLogId === log.id}
          />
        ))}
      </div>
    </div>
  );
};

export default AuditTrailDashboard;
