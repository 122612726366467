import React, { useState, useEffect } from 'react';
import { FaCode, FaExchangeAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';

const ApiIntegrationDashboard = () => {
  const [activeQuery, setActiveQuery] = useState(0);

  const queries = [
    {
      type: 'MongoDB',
      request: `
POST /mongodb/query
Content-Type: application/json
x-api-key: your_api_key_here

{
  "operation": "find",
  "database": "myapp",
  "collection": "users",
  "query": { "age": { "$gt": 18 } },
  "options": { "limit": 10 }
}`,
      response: `
{
  "result": [
    { "_id": "60d5ec9f1233c1f4b4d4b3a1", "name": "John Doe", "age": 30 },
    { "_id": "60d5ec9f1233c1f4b4d4b3a2", "name": "Jane Smith", "age": 25 },
    ...
  ]
}`,
    },
    {
      type: 'SQL',
      request: `
POST /sql/query
Content-Type: application/json
x-api-key: your_api_key_here

{
  "operation": "SELECT",
  "database": "myapp",
  "query": "SELECT name, age FROM users WHERE age > ? LIMIT 10",
  "params": [18]
}`,
      response: `
{
  "result": [
    { "name": "John Doe", "age": 30 },
    { "name": "Jane Smith", "age": 25 },
    ...
  ]
}`,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveQuery((prev) => (prev + 1) % queries.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-text-primary w-full min-w-[800px] p-8 h-full flex items-center justify-center">
      <div className="bg-background-card p-4 rounded-lg shadow-md">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <FaCode className="text-primary" />
            <span className="font-geist ml-2 font-bold">{queries[activeQuery].type} Query Example</span>
          </div>
         
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <motion.div
            key={`request-${activeQuery}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="bg-background-primary p-4 rounded-lg"
          >
            <h4 className="font-geist font-bold mb-2">Request</h4>
            <pre className="text-sm overflow-x-auto">
              <code>{queries[activeQuery].request}</code>
            </pre>
          </motion.div>
          <motion.div
            key={`response-${activeQuery}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="bg-background-primary p-4 rounded-lg"
          >
            <h4 className="font-geist font-bold mb-2">Response</h4>
            <pre className="text-sm overflow-x-auto">
              <code>{queries[activeQuery].response}</code>
            </pre>
          </motion.div>
        </div>
      </div>

    </div>
  );
};

export default ApiIntegrationDashboard;
