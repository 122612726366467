import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";

const generateDataFromLogs = (logs, database_id) => {
  const data = [];
  const now = new Date();
  const timeIntervals = {};

  // Initialize time intervals for the past 24 hours
  for (let i = 0; i < 24; i++) {
    const time = new Date(now.getTime() - i * 60 * 60 * 1000).toISOString().slice(0, 13); // Get the ISO string and slice to the hour
    timeIntervals[time] = 0;
    data.push({ time, queries: 0 });
  }

  // Process logs to count queries per hour
  logs.forEach(log => {
    if (log.database_id === database_id) {
      const executionTime = new Date(log.execution_time).toISOString().slice(0, 13); // Get the ISO string and slice to the hour
      if (timeIntervals[executionTime] !== undefined) {
        timeIntervals[executionTime] += 1;
      }
    }
  });

  // Populate data with query counts
  data.forEach(item => {
    item.queries = timeIntervals[item.time];
  });

  return data.reverse();
};


const DatabaseQueryChart = ({ logs, databaseName, database_id }) => {
  const [data, setData] = useState([]);

  console.log("ID", database_id, logs);

  useEffect(() => {
    setData(generateDataFromLogs(logs, database_id));
  }, [logs, databaseName]);

  // Calculate average queries per minute for display
  const avgQueriesPerMinute = (
    data.reduce((acc, curr) => acc + parseFloat(curr.queries), 0) / data.length
  ).toFixed(2);

  // Find max queries value for scaling
  const maxQueries = Math.max(...data.map((item) => parseFloat(item.queries)));

  return (
    <div className="w-full cursor-crosshair">
      <ResponsiveContainer width="100%" height={100}>
        <LineChart
          margin={{ top: 4, right: 0, bottom: 4, left: 0 }}
          data={data}
          style={{ cursor: "crosshair" }}
        >
          <XAxis dataKey="time" hide />
          <YAxis domain={[0, maxQueries + 10]} hide />
          <Line
            type="monotone"
            strokeWidth={1.5}
            dataKey="queries"
            stroke="#0056B3"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
      <p className="text-[#aaaaaa] font-dm font-light italic text-xs py-3 w-full text-end px-3">
        <b className="text-white font-medium not-italic">
          {avgQueriesPerMinute}
        </b>{" "}
        queries / hour
      </p>
    </div>
  );
};

export default DatabaseQueryChart;
