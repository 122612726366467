import React, { useState, useRef, useEffect } from "react";
import { FaEllipsisV, FaTrash, FaUserShield, FaUser } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import Modal from "../Modal";
import Button from "../Button";

const UserCard = ({ user, onDelete, onChangeRole, currentUser, currentRole }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChangeRoleModal, setShowChangeRoleModal] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const cardRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleContextMenu = (e) => {
    if (
      user.role === "owner" ||
      user.user_id === currentUser.user_id ||
      currentRole !== "owner"
    ) {
      return;
    }
    e.preventDefault();
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const adjustedX = Math.min(x, rect.width - 150);
    const adjustedY = Math.min(y, rect.height - 50);

    setMenuPosition({ x: adjustedX, y: adjustedY });
    setShowMenu(true);
  };

  const handleTouchStart = () => {
    if (
      user.role === "owner" ||
      user.user_id === currentUser.user_id ||
      currentRole !== "owner"
    )
      return;
    const longPressTimer = setTimeout(() => {
      const rect = cardRef.current.getBoundingClientRect();
      setMenuPosition({
        x: rect.width - 160,
        y: 10,
      });
      setShowMenu(true);
    }, 500);

    return () => clearTimeout(longPressTimer);
  };

  const handleDelete = () => {
    onDelete(user.user_id);
    setShowDeleteModal(false);
  };

  const handleChangeRole = () => {
    const newRole = user.role === "member" ? "admin" : "member";
    onChangeRole(user.user_id, newRole);
    setShowChangeRoleModal(false);
  };

  return (
    <>
      <motion.div
        ref={cardRef}
        onContextMenu={handleContextMenu}
        onTouchStart={handleTouchStart}
        onTouchEnd={() => clearTimeout(handleTouchStart())}
        className="bg-[#111011] border-[#2F2F2F] border rounded-lg p-6 px-8 flex flex-row items-center h-auto relative group"
        whileHover={{ scale: 1.02 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <div className="w-[50px] h-[50px] bg-[#0056B3] outline outline-[#0056B3] outline-offset-2 rounded-full text-2xl flex items-center justify-center text-white font-dm font-extralight">
          {user.email[0].toUpperCase()}
        </div>

        <div className="flex flex-col ml-4">
          <h1 className="font-dm font-normal text-white">{user.email}</h1>
          <h3 className="font-dm font-light text-sm text-white">
            {user.role[0].toUpperCase() + user.role.slice(1)}
          </h3>
        </div>

        {user.role !== "owner" &&
          user.user_id !== currentUser.user_id &&
          currentRole === "owner" && (
            <motion.div
              className="absolute top-2 right-2 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              whileHover={{ scale: 1.2 }}
            >
              <FaEllipsisV onClick={() => setShowMenu(!showMenu)} />
            </motion.div>
          )}

        <AnimatePresence>
          {showMenu && (
            <motion.div
              ref={menuRef}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.2 }}
              style={{
                position: "absolute",
                top: `${menuPosition.y}px`,
                left: `${menuPosition.x}px`,
              }}
              className="bg-[#1A1A1A] border border-[#3F3F3F] rounded-md shadow-lg z-50"
            >
              <motion.button
                onClick={() => setShowChangeRoleModal(true)}
                className="flex items-center w-full text-left px-4 py-2 text-sm text-white hover:bg-[#2F2F2F] transition-colors duration-150 ease-in-out"
                whileHover={{ backgroundColor: "#2F2F2F" }}
              >
                {user.role === "member" ? (
                  <>
                    <FaUserShield className="mr-2 text-blue-500" /> Promote to
                    Admin
                  </>
                ) : (
                  <>
                    <FaUser className="mr-2 text-yellow-500" /> Change to Member
                  </>
                )}
              </motion.button>
              <motion.button
                onClick={() => setShowDeleteModal(true)}
                className="flex items-center w-full text-left px-4 py-2 text-sm text-white hover:bg-[#2F2F2F] transition-colors duration-150 ease-in-out"
                whileHover={{ backgroundColor: "#2F2F2F" }}
              >
                <FaTrash className="mr-2 text-red-500" /> Remove User
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>

      <AnimatePresence>
        {showDeleteModal && (
          <Modal
            setShowModal={setShowDeleteModal}
            title="Confirm Remove User"
            subtitle="Are you sure you want to remove this user?"
            icon={<FaTrash className="text-red-500" />}
          >
            <Button onClick={handleDelete} variation="primary">
              Confirm
            </Button>
          </Modal>
        )}
        {showChangeRoleModal && (
          <Modal
            setShowModal={setShowChangeRoleModal}
            title="Confirm Change Role"
            icon={<FaUserShield className="text-blue-500" />}
          >
            <div className="w-full h-full flex flex-col gap-4">
              <p className="font-dm text-lg font-light text-white">
                Are you sure you want to change this user's role to{" "}
                <span className="font-bold text-blue-500">
                  {user.role === "member" ? "admin" : "member"}
                </span>
                ?
              </p>
              <Button onClick={handleChangeRole} variation="primary">
                Confirm
              </Button>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default UserCard;
