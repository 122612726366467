import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import {
  FiShield,
  FiLock,
  FiDatabase,
  FiAlertTriangle,
  FiKey,
  FiEye,
  FiUnlock,
  FiArrowRight,
  FiRefreshCw,
  FiCheck,
  FiServer,
} from "react-icons/fi";
import DynamicKeyDashboard from "./DynamicKeyDashboard";
import AccessControlDashboard from "./AccessKeyDashboard";
import BreachDetectionDashboard from "./BreachDetectionDashboard";
import AuditTrailDashboard from "./AuditTrailDashboard";
import ApiIntegrationDashboard from "./ApiIntegrationDashboard";
import Button from "../Button";
import AdvantageAnimations from "./AdvantageAnimations";
import HeroVisual from "./HeroVisual";
import { useNavigate } from "react-router-dom";
import PricingSection from "./PricingSection";
import Footer from "./Footer";

const Home = () => {
  const [activeFeature, setActiveFeature] = useState(0);
  const navigate = useNavigate();

  const features = [
    {
      icon: FiLock,
      title: "Dynamic Key Generation",
      description:
        "Automatically create and rotate access keys to enhance security and prevent unauthorized access.",
      dashboard: <DynamicKeyDashboard />,
    },
    {
      icon: FiShield,
      title: "Granular Access Control",
      description:
        "Define and manage precise permissions for users, roles, and applications with easy-to-use interfaces.",
      dashboard: <AccessControlDashboard />,
    },
    {
      icon: FiAlertTriangle,
      title: "Proactive Breach Detection",
      description:
        "Utilize AI-powered algorithms to identify and respond to potential security threats in real-time.",
      dashboard: <BreachDetectionDashboard />,
    },
    {
      icon: FiEye,
      title: "Comprehensive Audit Trail",
      description:
        "Maintain detailed, tamper-proof logs of all database activities for compliance and forensic analysis.",
      dashboard: <AuditTrailDashboard />,
    },
    {
      icon: FiKey,
      title: "Seamless API Integration",
      description:
        "Easily integrate robust security features into your existing infrastructure with our API-first approach.",
      dashboard: <ApiIntegrationDashboard />,
    },
  ];

  const handleFeatureClick = (index) => {
    setActiveFeature(index);
  };

  const circleVariants = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: {
          duration: 5,
          ease: "easeInOut",
          repeat: Infinity,
          repeatType: "reverse",
        },
        opacity: { duration: 2, repeat: Infinity, repeatType: "reverse" },
      },
    },
  };

  return (
    <div className="min-h-full w-full">
      <div className="max-w-[1600px] py-16 px-4 sm:px-6 lg:px-8 flex flex-col gap-48 mx-auto">

        <div className="relative min-h-[60vh] flex items-center justify-center overflow-hidden">
          <motion.div
            animate={{
              scale: [0.8, 1, 1, 0.8],
              opacity: [0.5, 1, 1, 0.5],
            }}
            transition={{
              duration: 10,
              ease: "easeInOut",
              repeat: Infinity,
              repeatType: "loop",
            }}
            className="absolute inset-0 -z-20"
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid slice"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.circle
                cx="50"
                cy="50"
                r="49"
                stroke="url(#grad)"
                strokeWidth="2"
                vectorEffect="non-scaling-stroke"
                variants={circleVariants}
                initial="hidden"
                animate="visible"
              />
              <motion.circle
                cx="50"
                cy="50"
                r="44"
                stroke="url(#grad)"
                strokeWidth="2"
                vectorEffect="non-scaling-stroke"
                strokeDasharray="1 2"
                variants={circleVariants}
                initial="hidden"
                animate="visible"
                transition={{
                  delay: 0.5,
                }}
              />
              <defs>
                <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="0%" stopColor="#3D3D3D" />
                  <stop offset="100%" stopColor="#2d2929" />
                </linearGradient>
              </defs>
            </svg>
          </motion.div>

          {/* Content */}
          <div className="relative z-10 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
              {/* Left Column: Text Content */}
              <div className="text-left">
                <motion.h1
                  className="font-geist text-5xl sm:text-6xl font-bold text-white mb-6 leading-tight"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  Secure Your Data.
                  <br />
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0056C8] to-[#3B82F6]">
                    Empower Your Team.
                  </span>
                </motion.h1>
                <motion.p
                  className="font-dm text-xl text-[#E5E7EB] mb-8"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                >
                  ThirtyFirst delivers next-gen database security, ensuring
                  ironclad protection without sacrificing performance or ease of
                  use.
                </motion.p>
                <motion.button
                  onClick={() => navigate("/signup")}
                  className="bg-[#0056C8] text-white px-8 py-4 rounded-full font-semibold text-lg transition-all hover:bg-[#0056B3] inline-flex items-center"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Get Started
                  <FiKey className="ml-2" />
                </motion.button>
              </div>

              {/* Right Column: Interactive Visual */}
              <HeroVisual />
            </div>
          </div>
        </div>

        <div className="w-full min-h-[80vh] grid grid-cols-1 lg:grid-cols-5 gap-20 items-center mb-20">
          <div className="lg:col-span-2 space-y-6">
            <h2 className="font-geist text-2xl md:text-3xl font-bold text-text-primary">
              Comprehensive Security Suite
            </h2>
            <p className="font-dm font-sm text-text-secondary mb-8">
              Our cutting-edge tools provide ironclad defense against data
              breaches, unauthorized access, and compliance violations.
            </p>
            <div className="space-y-4">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  className={`flex items-center p-4 rounded-lg transition-all duration-300 cursor-pointer ${
                    index === activeFeature
                      ? "bg-primary shadow-lg"
                      : "bg-background-card"
                  }`}
                  initial={false}
                  animate={{ scale: index === activeFeature ? 1.05 : 1 }}
                  onClick={() => handleFeatureClick(index)}
                >
                  <feature.icon
                    className={`text-3xl w-10 h-10 ${
                      index === activeFeature
                        ? "text-text-primary"
                        : "text-primary"
                    } mr-4`}
                  />
                  <div>
                    <h3 className="font-geist text-md font-semibold text-text-primary">
                      {feature.title}
                    </h3>
                    <p className="font-dm text-sm text-text-secondary">
                      {feature.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
          <div className="relative w-full overflow-auto lg:col-span-3">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeFeature}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.5 }}
                className="relative h-[600px] border border-[#2F2F2F] bg-[#1a1a1a] rounded-lg shadow-2xl"
              >
                <div className="w-full h-8 flex items-center justify-start px-4 bg-[#242324] border-b border-[#2F2F2F]">
                  <div className="w-3 h-3 bg-[#FF605C] rounded-full mr-2" />
                  <div className="w-3 h-3 bg-[#FFBD44] rounded-full mr-2" />
                  <div className="w-3 h-3 bg-[#00CA4E] rounded-full mr-2" />
                  <div className="flex-grow text-center text-sm text-[#898A8C] font-geist">
                    {features[activeFeature].title}
                  </div>
                </div>
                <div className="w-full h-[calc(100%-2rem)] p-4 overflow-y-auto">
                  {features[activeFeature].dashboard}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>

        <AdvantageAnimations />
        <PricingSection />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
