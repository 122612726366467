import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

const Input = ({
  label,
  value,
  onChange,
  placeholder,
  type,
  disabled,
  error,
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={`w-full ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}>
      <label className="block text-[#AAAAAA] text-sm font-medium font-dm mb-2">
        {label}
      </label>
      <div className="relative">
        <input
          type={type === "password" && isPasswordVisible ? "text" : type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          className={`font-dm shadow appearance-none border rounded-lg w-full py-3 px-3 text-[#AAAAAA] leading-tight focus:outline-none focus:shadow-outline ${
            error ? "border-red-500" : "border-[#37393C]"
          } ${disabled ? "cursor-not-allowed bg-[#1C1D1F]" : "bg-[#232426]"}`}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400"
            onClick={togglePasswordVisibility}
          >
            {isPasswordVisible ? (
              <IconEye size={20} />
            ) : (
              <IconEyeOff size={20} />
            )}
          </button>
        )}
      </div>
      {error && <p className="text-red-500 text-xs italic mt-2">{error}</p>}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

export default Input;
