import React, { useState, useRef, useEffect } from "react";
import { FaEllipsisV, FaEye, FaKey, FaPen, FaTrash } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import Modal from "../Modal";
import Button from "../Button";

const AccessIcon = ({ icon, explanation }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`transition-colors duration-200 ${
          isHovered ? "text-[#007BFF]" : "text-[#aaa]"
        }`}
      >
        {icon}
      </div>
      <AnimatePresence>
        {isHovered && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute z-50 bg-[#1A1A1A] border border-[#3F3F3F] rounded-md p-2 text-xs text-white whitespace-nowrap"
            style={{ top: "100%", left: "50%", transform: "translateX(-50%)" }}
          >
            {explanation}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const getAccessLevelIcons = ({ read_access, write_access, delete_access }) =>
  [
    {
      access: read_access,
      icon: <FaEye key="read" />,
      explanation: "Read Access",
    },
    {
      access: write_access,
      icon: <FaPen key="write" />,
      explanation: "Write Access",
    },
    {
      access: delete_access,
      icon: <FaTrash key="delete" />,
      explanation: "Delete Access",
    },
  ].filter(({ access }) => access);

const PermissionRow = ({ permission, onDelete }) => {
  const {
    permission_name,
    data_type,
    fields,
    read_access,
    write_access,
    delete_access,
  } = permission;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDelete = () => {
    onDelete(permission.permission_id);
    setShowDeleteModal(false);
  };

  return (
    <div className="w-full flex flex-row bg-[#111011] border-[#2F2F2F] border mt-4 rounded-xl hover:bg-[#242324] items-center p-4">
      <div className="w-[25%] flex flex-row gap-4 items-center">
        <div className="w-6 h-6 bg-[#0056B3] outline outline-[#0056B3] hover:bg-[#0049A6] outline-offset-2 rounded-full flex items-center justify-center">
          <FaKey className="text-white text-xs" />
        </div>
        <p className="text-white font-geist font-normal">{permission_name}</p>
      </div>
      <div className="w-[20%] flex items-center gap-2">
        <div className="text-sm py-2 px-2 rounded flex gap-3">
          {getAccessLevelIcons({
            read_access,
            write_access,
            delete_access,
          }).map(({ icon, explanation }) => (
            <AccessIcon
              key={explanation}
              icon={icon}
              explanation={explanation}
            />
          ))}
        </div>
      </div>
      <div className="w-[20%]">
        <p className="text-white font-geist font-normal text-sm">
          {data_type[0].toUpperCase() + data_type.slice(1)}
        </p>
      </div>
      <div className="w-[30%]">
        <p className="text-[#7F7F7F] font-geist font-normal text-sm flex flex-row flex-wrap gap-1">
          {fields.map((field) => (
            <span
              key={field}
              className={`inline-block bg-[#111111] py-1 px-2 rounded-md hover:text-[#007BFF]`}
            >
              {field[0].toUpperCase() + field.slice(1)}
            </span>
          ))}
        </p>
      </div>
      <div className="relative w-[5%]" ref={menuRef}>
        <button
          className={`p-2 ml-auto text-white rounded-lg flex items-center hover:text-[#a9a9a9]`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <FaEllipsisV />
        </button>
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.2 }}
              className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-[#1A1A1A] border border-[#3F3F3F] z-50"
            >
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <button
                  onClick={() => setShowDeleteModal(true)}
                  className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-[#2F2F2F] transition-colors duration-150 ease-in-out"
                  role="menuitem"
                >
                  <FaTrash className="inline-block mr-2 text-red-500" /> Delete
                </button>
                {/* Add more menu items here in the future */}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {showDeleteModal && (
          <Modal
            setShowModal={setShowDeleteModal}
            title="Confirm Remove Permission"
            subtitle="Are you sure you want to remove this permission?"
            icon={<FaTrash className="text-red-500" />}
          >
            <Button onClick={handleDelete} variation="primary">
              Confirm
            </Button>
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PermissionRow;
