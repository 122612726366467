import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiShield, FiLock, FiDatabase } from "react-icons/fi";
import LandingBackground from "../assets/LandingBackground.svg";
import Header from "../components/Landing/Header";
import Home from "../components/Landing/Home";

// Main Landing Component
const Landing = () => {
  const [activeSection, setActiveSection] = useState("home");

  return (
    <div className="flex overflow-x-hidden flex-col w-screen h-screen relative bg-[#0F0E0F]">
      <Header setActiveSection={setActiveSection} />
      <img
        src={LandingBackground}
        className="w-full h-full absolute bottom-0 right-0 z-0 pointer-events-none object-cover"
      />
      <main className="flex-grow overflow-auto pt-24 px-1 md:px-8 pb-8 relative z-10">
        <AnimatePresence mode="wait">
          <motion.div
            key={activeSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {activeSection === "home" && <Home />}
          </motion.div>
        </AnimatePresence>
      </main>
    </div>
  );
};

export default Landing;
