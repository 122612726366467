import React from "react";
import { FiX } from "react-icons/fi";

const Modal = React.memo(
  ({ children, setShowModal, title, subtitle, icon }) => {
    return (
      <div className="w-screen h-screen flex items-center justify-center fixed top-0 left-0 z-40 overflow-hidden bg-black bg-opacity-70">
        <div className="relative shadow-2xl shadow-black w-4/5 max-w-[500px] max-h-[600px] overflow-auto rounded-lg bg-[#0F0E0F] border-[#2F2F2F] border flex flex-col items-center p-8">
          <div className="w-full flex items-start justify-between mb-4">
            <div className="flex flex-col items-start justify-start gap-4">
              <div className="min-w-10 min-h-10 rounded-md bg-[#0F0E0F] border-[#37393C] border flex items-center justify-center">
                <p className="font-dm text-2xl font-medium text-center text-white">
                  {icon}
                </p>
              </div>
              <div className="flex flex-col">
                <h1 className="font-dm text-lg font-bold text-white">
                  {title}
                </h1>
                <h3 className="font-dm text-sm text-white mt-2 truncate">
                  {subtitle}
                </h3>
              </div>
            </div>
            <button
              className="w-6 h-6 flex items-center justify-center"
              onClick={() => setShowModal(false)}
            >
              <FiX size={24} color="white" />
            </button>
          </div>
          {children}
        </div>
      </div>
    );
  }
);

export default Modal;
