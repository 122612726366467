import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  addUserToEnterprise,
  removeUserFromEnterprise,
  changeUserRole,
} from "../../utils/apiFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import UserCard from "./UserCard";
import Button from "../Button";
import Input from "../Input";
import Select from "../Select";

const Users = ({ enterpriseDetails }) => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("member");
  const [usersFormOpen, setUsersFormOpen] = useState(false);
  const queryClient = useQueryClient();
  const { user: currentUser } = useSelector((state) => state.auth);

  // Get the role of the currentUser from enterpriseDetails
  const currentUserRole = enterpriseDetails.role

  const addUserMutation = useMutation({
    mutationFn: addUserToEnterprise,
    onSuccess: () => {
      queryClient.invalidateQueries(["enterpriseDetails"]);
      toast.success("User added successfully");
      setEmail("");
      setRole("member");
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message || "Error adding user";
      toast.error(errorMessage);
    },
  });

  const removeUserMutation = useMutation({
    mutationFn: removeUserFromEnterprise,
    onSuccess: () => {
      queryClient.invalidateQueries(["enterpriseDetails"]);
      toast.success("User removed successfully");
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message || "Error removing user";
      toast.error(errorMessage);
    },
  });

  const changeRoleMutation = useMutation({
    mutationFn: changeUserRole,
    onSuccess: () => {
      queryClient.invalidateQueries(["enterpriseDetails"]);
      toast.success("User role updated successfully");
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message || "Error changing user role";
      toast.error(errorMessage);
    },
  });

  const handleAddUser = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter an email");
      return;
    }
    addUserMutation.mutate({
      email,
      role,
      enterprise_id: enterpriseDetails.enterprise.enterprise_id,
    });
  };

  const handleUserRoleChange = (userId, newRole) => {
    changeRoleMutation.mutate({
      user_id: userId,
      new_role: newRole,
      enterprise_id: enterpriseDetails.enterprise.enterprise_id,
    });
  };

  const handleUserDelete = (userId) => {
    removeUserMutation.mutate({
      user_id: userId,
      enterprise_id: enterpriseDetails.enterprise.enterprise_id,
    });
  };

  const sortUsers = (users) => {
    const rolePriority = { owner: 1, admin: 2, member: 3 };

    return users.sort((a, b) => {
      if (rolePriority[a.role] !== rolePriority[b.role]) {
        return rolePriority[a.role] - rolePriority[b.role];
      }
      return a.email.localeCompare(b.email);
    });
  };

  if (!enterpriseDetails) return null;

  return (
    <div className="w-full h-full p-8 flex flex-col">
      <div className="w-full flex flex-row items-center justify-between relative">
        <h1 className="text-white font-geist text-2xl">Users</h1>
        {currentUserRole === "owner" && (
          <div>
            <Button onClick={() => setUsersFormOpen(!usersFormOpen)}>
              <div className="w-full items-center flex flex-row justify-center gap-2">
                Add User
                {usersFormOpen ? (
                  <FaChevronUp className="text-sm" />
                ) : (
                  <FaChevronDown className="text-sm" />
                )}
              </div>
            </Button>
          </div>
        )}
        <AnimatePresence>
          {usersFormOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="absolute top-12 z-20 right-0 bg-[#1A1A1A] border border-[#2F2F2F] rounded-lg shadow-sm shadow-black"
            >
              <UsersForm
                email={email}
                setEmail={setEmail}
                role={role}
                setRole={setRole}
                handleAddUser={handleAddUser}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="w-full h-full flex flex-row space-evenly mt-6">
        <div className="w-full h-full flex flex-row flex-wrap gap-4">
          {sortUsers(enterpriseDetails.users).map((user) => (
            <div key={user.email}>
              <UserCard
                onDelete={(userId) => handleUserDelete(userId)}
                onChangeRole={(userId, newRole) =>
                  handleUserRoleChange(userId, newRole)
                }
                user={user}
                currentUser={currentUser}
                currentRole={currentUserRole}
              />
            </div>
          ))}
        </div>
      </div>
      {/* Once lay toast here */}
    </div>
  );
};

const UsersForm = ({ email, setEmail, role, setRole, handleAddUser }) => {
  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "member", label: "Member" },
  ];

  return (
    <form
      onSubmit={handleAddUser}
      className="min-w-[300px] w-full max-w-md h-full p-6 flex flex-col bg-[#1A1A1A] text-white rounded-lg shadow-lg"
    >
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2">Add New User</h2>
        <p className="text-sm text-[#AAAAAA]">
          Fill in the details below to add a new user.
        </p>
      </div>

      <div className="mb-4">
        <Input
          label="User Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="User Email"
        />
      </div>

      <div className="mb-6">
        <Select
          label="Role"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          options={roleOptions}
          placeholder="Select Role"
        />
      </div>

      <Button type="submit" variation="primary">
        Add User
      </Button>
    </form>
  );
};

export default Users;