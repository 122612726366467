import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  addPermission,
  removePermission,
  fetchDatabaseSchema,
} from "../../utils/apiFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import PermissionRow from "./PermissionRow";
import Button from "../Button";
import Input from "../Input";
import Checkbox from "../Checkbox";
import Select from "../Select";
import { ClipLoader } from "react-spinners";

const Permissions = ({ enterpriseDetails }) => {
  const [permissionName, setPermissionName] = useState("");
  const [dataType, setDataType] = useState("");
  const [fields, setFields] = useState([]);
  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [schema, setSchema] = useState([]);
  const queryClient = useQueryClient();
  const [permissionFormOpen, setPermissionFormOpen] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);

  const addPermissionMutation = useMutation({
    mutationFn: addPermission,
    onSuccess: () => {
      queryClient.invalidateQueries(["enterpriseDetails"]);
      toast.success("Permission added successfully");
      setPermissionName("");
      setDataType("");
      setFields([]);
      setReadAccess(false);
      setWriteAccess(false);
      setDeleteAccess(false);
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message || "Error adding permission";
      toast.error(errorMessage);
    },
  });

  const removePermissionMutation = useMutation({
    mutationFn: removePermission,
    onSuccess: () => {
      queryClient.invalidateQueries(["enterpriseDetails"]);
      toast.success("Permission removed successfully");
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message || "Error removing permission";
      toast.error(errorMessage);
    },
  });

  const handleAddPermission = (e) => {
    e.preventDefault();
    if (!permissionName || !dataType || fields.length === 0) {
      toast.error("All fields are required");
      return;
    }
    addPermissionMutation.mutate({
      enterprise_id: enterpriseDetails.enterprise.enterprise_id,
      permission_name: permissionName,
      data_type: dataType,
      fields,
      read_access: readAccess,
      write_access: writeAccess,
      delete_access: deleteAccess,
    });
  };

  const handleDeletePermission = (permissionId) => {
    removePermissionMutation.mutate({
      enterprise_id: enterpriseDetails.enterprise.enterprise_id,
      permission_id: permissionId,
    });
  };

  const handleFetchSchema = async ({ database_id }) => {
    try {
      const response = await fetchDatabaseSchema(database_id);
      if (response && Array.isArray(response)) {
        setSchema(response);
        return response;
      } else {
        console.error("Unexpected schema response:", response);
        throw new Error("Received invalid schema data");
      }
    } catch (error) {
      console.error("Error fetching schema:", error);
      if (error.response) {
        throw new Error(error.response.data.message || "Server error occurred");
      } else if (error.request) {
        throw new Error("No response received from server");
      } else {
        throw new Error("Error setting up the request");
      }
    }
  };

  const databases = enterpriseDetails.databases;

  if (!enterpriseDetails) return null;

  return (
    <div className="w-full h-full p-8 flex flex-col">
      <div className="w-full flex flex-row items-center justify-between relative">
        <h1 className="text-white font-geist text-2xl">Permissions</h1>
        <div>
          <Button onClick={() => setPermissionFormOpen(!permissionFormOpen)}>
            <div className="w-full items-center flex flex-row justify-center gap-2">
              Add Permission
              {permissionFormOpen ? (
                <FaChevronUp className="text-sm" />
              ) : (
                <FaChevronDown className="text-sm" />
              )}
            </div>
          </Button>
        </div>
        <AnimatePresence>
          {permissionFormOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="absolute top-12 z-20 right-0 bg-[#1A1A1A] border border-[#2F2F2F] rounded-lg shadow-sm shadow-black"
            >
              <PermissionForm
                permissionName={permissionName}
                setPermissionName={setPermissionName}
                readAccess={readAccess}
                setReadAccess={setReadAccess}
                writeAccess={writeAccess}
                setWriteAccess={setWriteAccess}
                deleteAccess={deleteAccess}
                setDeleteAccess={setDeleteAccess}
                handleAddPermission={handleAddPermission}
                handleFetchSchema={handleFetchSchema}
                dataType={dataType}
                setDataType={setDataType}
                fields={fields}
                setFields={setFields}
                setSelectedDatabase={setSelectedDatabase}
                schema={schema}
                databases={databases}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="w-full h-full flex flex-row space-evenly mt-6">
        <div className="w-full h-full flex flex-col gap-4">
          <div className="overflow-auto lg:overflow-visible flex flex-col">
            <div className="w-full flex flex-row bg-[#1A1A1A] text-[#AAAAAA] font-dm font-medium rounded-lg ">
              <h1 className="w-[25%] py-3 px-6 hover:text-[#BBBBBD] transition-all transition-200">
                Name
              </h1>
              <h1 className="w-[20%] py-3 hover:text-[#BBBBBD] transition-all transition-200">
                Access Level
              </h1>
              <h1 className="w-[20%] py-3 hover:text-[#BBBBBD] transition-all transition-200">
                Data Type
              </h1>
              <h1 className="w-[35%] py-3 hover:text-[#BBBBBD] transition-all transition-200">
                Fields
              </h1>
            </div>
            {enterpriseDetails.permissions.map((permission) => (
              <PermissionRow
                key={permission.permission_id}
                permission={permission}
                onDelete={() =>
                  handleDeletePermission(permission.permission_id)
                }
              />
            ))}
          </div>
        </div>
      </div>
      {/* Once lay toast here */}
    </div>
  );
};

const PermissionForm = ({
  permissionName,
  setPermissionName,
  readAccess,
  setReadAccess,
  writeAccess,
  setWriteAccess,
  deleteAccess,
  setDeleteAccess,
  handleAddPermission,
  handleFetchSchema,
  dataType,
  setDataType,
  fields,
  setFields,
  setSelectedDatabase,
  schema,
  databases,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedDatabaseState, setSelectedDatabaseState] = useState("");

  const handleDatabaseChange = (e) => {
    const selected = databases.find(
      (db) => db.database_id === parseInt(e.target.value)
    );
    setSelectedDatabase(selected);
    setSelectedDatabaseState(e.target.value);
  };

  const fetchSchema = async () => {
    if (!selectedDatabaseState) {
      toast.error("Please select a database first");
      return;
    }

    setLoading(true);
    try {
      const result = await handleFetchSchema({
        database_id: parseInt(selectedDatabaseState),
      });
      if (result && Array.isArray(result) && result.length > 0) {
        toast.success("Schema fetched successfully");
      } else {
        toast.warn("Schema fetched, but it appears to be empty");
      }
    } catch (error) {
      console.error("Error in fetchSchema:", error);
      toast.error(error.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-w-[300px] max-h-[600px] overflow-auto w-full max-w-md h-full p-6 flex flex-col bg-[#1A1A1A] text-white rounded-lg shadow-lg">
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2">Create New Permission</h2>
        <p className="text-sm text-[#AAAAAA]">
          Select a database and fetch the schema before adding a new permission.
        </p>
      </div>

      <div className="mb-4">
        <Select
          label="Select Database"
          value={selectedDatabaseState}
          onChange={handleDatabaseChange}
          options={databases.map((db) => ({
            value: db.database_id.toString(),
            label: db.database_name,
          }))}
          placeholder="Select Database"
        />
      </div>

      <div className="mb-6">
        <Button onClick={fetchSchema} disabled={loading}>
          {loading ? <ClipLoader size={20} color="#fff" /> : "Fetch Schema"}
        </Button>
      </div>

      {schema.length > 0 && !loading && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="mb-4">
            <Select
              label="Select Table"
              value={dataType}
              onChange={(e) => setDataType(e.target.value)}
              options={schema.map((table) => ({
                value: table.table,
                label: table.table,
              }))}
              placeholder="Select Table"
            />
          </div>

          {dataType && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">Select Fields</h3>
              <div className="grid grid-cols-2 gap-2">
                {schema
                  .find((table) => table.table === dataType)
                  ?.columns?.map((field) => (
                    <label
                      key={field}
                      className="p-2 bg-[#232426] border border-[#2F2F2F] rounded-lg flex items-center"
                    >
                      <Checkbox
                        label={field}
                        value={field}
                        checked={fields.includes(field)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFields([...fields, e.target.value]);
                          } else {
                            setFields(
                              fields.filter((f) => f !== e.target.value)
                            );
                          }
                        }}
                      />
                    </label>
                  ))}
              </div>
            </div>
          )}

          <div className="mb-4">
            <Input
              label="Permission Name"
              value={permissionName}
              onChange={(e) => setPermissionName(e.target.value)}
              placeholder="Permission Name"
            />
          </div>

          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Access Levels</h3>
            <div className="flex flex-col space-y-2">
              <Checkbox
                label="Read Access"
                checked={readAccess}
                onChange={(e) => setReadAccess(e.target.checked)}
              />
              <Checkbox
                label="Write Access"
                checked={writeAccess}
                onChange={(e) => setWriteAccess(e.target.checked)}
              />
              <Checkbox
                label="Delete Access"
                checked={deleteAccess}
                onChange={(e) => setDeleteAccess(e.target.checked)}
              />
            </div>
          </div>

          <Button onClick={handleAddPermission}>Add Permission</Button>
        </motion.div>
      )}
    </div>
  );
};

export default Permissions;
