import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FiCheck,
  FiX,
  FiArrowRight,
  FiShield,
  FiDatabase,
  FiServer,
  FiZap,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const Node = ({ x, y, icon: Icon, color, size = 40, pulse = false }) => (
  <g transform={`translate(${x}, ${y})`}>
    <circle r={size / 2} fill="#1A1A1A" stroke={color} strokeWidth="2" />
    {pulse && (
      <circle
        r={size / 2}
        fill="none"
        stroke={color}
        strokeWidth="2"
        opacity="0.5"
      >
        <animate
          attributeName="r"
          from={size / 2}
          to={size}
          dur="1.5s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="0.5"
          to="0"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </circle>
    )}
    <foreignObject
      x={-size / 4}
      y={-size / 4}
      width={size / 2}
      height={size / 2}
    >
      <div className="w-full h-full flex items-center justify-center">
        <Icon className="w-full h-full" color={color} />
      </div>
    </foreignObject>
  </g>
);

const DataPath = ({ d, color }) => (
  <g>
    <path
      d={d}
      stroke={color}
      strokeWidth="2"
      strokeDasharray="4 4"
      fill="none"
    />
    <circle r="4" fill={color}>
      <animateMotion path={d} dur="5s" repeatCount="indefinite" />
    </circle>
  </g>
);

const PricingAnimation = ({ tier }) => {
  const colorScheme = {
    Free: { primary: "#0056C8", secondary: "#3B82F6" },
    Pro: { primary: "#7C3AED", secondary: "#C084FC" },
    Enterprise: { primary: "#F59E0B", secondary: "#FDBA74" },
  };

  const currentColors = colorScheme[tier] || colorScheme.Free;

  const FreePlan = () => (
    <svg viewBox="0 0 300 200" className="w-full h-64">
      <g>
        <DataPath d="M75,100 Q150,75 225,100" color={currentColors.primary} />
        <DataPath
          d="M225,100 Q150,125 75,100"
          color={currentColors.secondary}
        />
        <Node
          x={150}
          y={100}
          icon={FiDatabase}
          color={currentColors.primary}
          size={60}
          pulse
        />
        <Node
          x={75}
          y={100}
          icon={FiServer}
          color={currentColors.secondary}
          size={40}
        />
        <Node
          x={225}
          y={100}
          icon={FiServer}
          color={currentColors.secondary}
          size={40}
        />
      </g>
    </svg>
  );

  const ProPlan = () => (
    <svg viewBox="0 0 300 200" className="w-full h-64">
      <g>
        <DataPath d="M75,50 Q150,75 225,50" color={currentColors.primary} />
        <DataPath
          d="M75,150 Q150,125 225,150"
          color={currentColors.secondary}
        />
        <DataPath d="M75,50 Q75,100 75,150" color={currentColors.primary} />
        <DataPath
          d="M225,50 Q225,100 225,150"
          color={currentColors.secondary}
        />
        <DataPath d="M150,25 Q150,62.5 150,100" color={currentColors.primary} />
        <Node
          x={150}
          y={100}
          icon={FiDatabase}
          color={currentColors.primary}
          size={60}
          pulse
        />
        <Node
          x={75}
          y={50}
          icon={FiServer}
          color={currentColors.secondary}
          size={40}
        />
        <Node
          x={225}
          y={50}
          icon={FiServer}
          color={currentColors.secondary}
          size={40}
        />
        <Node
          x={75}
          y={150}
          icon={FiServer}
          color={currentColors.secondary}
          size={40}
        />
        <Node
          x={225}
          y={150}
          icon={FiServer}
          color={currentColors.secondary}
          size={40}
        />
        <Node
          x={150}
          y={25}
          icon={FiZap}
          color={currentColors.primary}
          size={30}
        />
      </g>
    </svg>
  );

  const EnterprisePlan = () => (
    <svg viewBox="0 0 300 200" className="w-full h-64">
      <g>
        <DataPath d="M50,50 Q150,75 250,50" color={currentColors.primary} />
        <DataPath
          d="M50,150 Q150,125 250,150"
          color={currentColors.secondary}
        />
        <DataPath d="M50,50 Q50,100 50,150" color={currentColors.primary} />
        <DataPath
          d="M250,50 Q250,100 250,150"
          color={currentColors.secondary}
        />
        <DataPath d="M150,25 Q150,62.5 150,100" color={currentColors.primary} />
        <DataPath
          d="M150,175 Q150,137.5 150,100"
          color={currentColors.secondary}
        />
        <Node
          x={150}
          y={100}
          icon={FiDatabase}
          color={currentColors.primary}
          size={70}
          pulse
        />
        <Node
          x={50}
          y={50}
          icon={FiServer}
          color={currentColors.secondary}
          size={40}
        />
        <Node
          x={250}
          y={50}
          icon={FiServer}
          color={currentColors.secondary}
          size={40}
        />
        <Node
          x={50}
          y={150}
          icon={FiServer}
          color={currentColors.secondary}
          size={40}
        />
        <Node
          x={250}
          y={150}
          icon={FiServer}
          color={currentColors.secondary}
          size={40}
        />
        <Node
          x={150}
          y={25}
          icon={FiZap}
          color={currentColors.primary}
          size={40}
        />
        <Node
          x={150}
          y={175}
          icon={FiShield}
          color={currentColors.primary}
          size={40}
        />
      </g>
    </svg>
  );

  return (
    <div className="w-full mb-6">
      {tier === "Free" && <FreePlan />}
      {tier === "Pro" && <ProPlan />}
      {tier === "Enterprise" && <EnterprisePlan />}
    </div>
  );
};

const FeatureCategory = ({ category, isActive, onClick, color }) => (
  <motion.button
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    style={{ backgroundColor: isActive ? color : "transparent" }}
    className={`px-3 py-1 rounded-full text-xs font-medium font-dm ${
      isActive
        ? `text-white`
        : `bg-background-secondary text-text-secondary hover:bg-${color} hover:text-white`
    }`}
    onClick={() => onClick(category)}
  >
    {category}
  </motion.button>
);

const CreativeButton = ({ plan, color }) => {
  const navigate = useNavigate();

  console.error("color", color);

  return (
    <motion.div
    style={{ backgroundColor: color }}
      className={`relative w-full h-12 rounded-lg overflow-hidden cursor-pointer group`}
      whileHover="hover"
      whileTap="tap"
      onClick={() => navigate("/signup")}
    >
      <motion.div
        style={{ backgroundColor: color }}
        className={`absolute inset-0 flex items-center justify-center`}
        initial={{ y: "100%" }}
        variants={{
          hover: { y: 0 },
          tap: { y: 0 },
        }}
        transition={{ type: "spring", stiffness: 300, damping: 20 }}
      >
        <FiArrowRight className="text-white text-lg mr-2" />
        <span className="font-geist font-semibold text-sm text-white">
          Get Started
        </span>
      </motion.div>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="font-geist font-semibold text-sm text-white group-hover:opacity-0 transition-opacity duration-300">
          Choose {plan.name}
        </span>
      </div>
    </motion.div>
  );
};

const PlanCard = ({ plan, isPopular }) => {
  const [activeCategory, setActiveCategory] = useState("Database");
  const categories = ["Database", "API", "Features"];

  const cardColor = plan.color;
  const scale = isPopular ? 1.03 : 1;

  return (
    <motion.div
      className={`rounded-2xl p-6 flex flex-col bg-[#111011] border-[#2F2F2F] border relative overflow-hidden`}
      initial={{ opacity: 0, y: 50, scale }}
      animate={{ opacity: 1, y: 0, scale }}
      transition={{ duration: 0.5 }}
    >
      {isPopular && (
        <div className="absolute top-3 right-3 bg-accent-warning text-background-primary px-2 py-1 rounded-full text-xs font-bold font-dm">
          Popular
        </div>
      )}
      <h3 className="text-2xl font-bold mb-1 text-white font-geist">
        {plan.name}
      </h3>
      <p className="text-sm mb-3 text-text-secondary font-dm">
        {plan.description}
      </p>
      <PricingAnimation tier={plan.name} />
      <div className="flex items-baseline mb-4">
        <span className="text-3xl font-bold text-white font-geist">
          ${plan.price}
        </span>
        <span className="text-sm text-text-secondary ml-1 font-dm">/mo</span>
      </div>

      <div className="flex flex-wrap gap-2 mb-4">
        {categories.map((category) => (
          <FeatureCategory
            key={category}
            category={category}
            isActive={activeCategory === category}
            onClick={setActiveCategory}
            color={cardColor}
          />
        ))}
      </div>

      <AnimatePresence mode="wait">
        <motion.ul
          key={activeCategory}
          className="mb-4 flex-grow text-sm"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          {plan.features[activeCategory].map((feature, index) => (
            <motion.li
              key={index}
              className="flex items-center mb-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              {feature.included ? (
                <FiCheck className="text-accent-success mr-2 flex-shrink-0" />
              ) : (
                <FiX className="text-accent-error mr-2 flex-shrink-0" />
              )}
              <span className="text-text-secondary font-dm">
                {feature.name}
              </span>
            </motion.li>
          ))}
        </motion.ul>
      </AnimatePresence>

      <CreativeButton plan={plan} color={cardColor} />
    </motion.div>
  );
};

const PricingSection = () => {
  const plans = [
    {
      name: "Free",
      description: "For personal projects",
      price: 0,
      color: "#0056C8",
      features: {
        Database: [
          { name: "Up to 3 databases", included: true },
          { name: "30 Day Logs Retention", included: true },
          { name: "Basic query optimization", included: true },
        ],
        API: [
          { name: "100 API calls per day", included: true },
          { name: "Standard API access", included: true },
          { name: "Basic rate limiting", included: true },
        ],
        Features: [
          { name: "Community support", included: true },
          { name: "Basic monitoring", included: true },
          { name: "Standard encryption", included: true },
        ],
      },
    },
    {
      name: "Pro",
      description: "For growing projects and startups",
      price: 49,
      color: "#5c14d8",
      features: {
        Database: [
          { name: "Up to 10 databases", included: true },
          { name: "6 Month Logs Retention", included: true },
          { name: "Advanced query optimization", included: true },
        ],
        API: [
          { name: "1000 API calls per day", included: true },
          { name: "Priority API access", included: true },
          { name: "Custom rate limits", included: true },
        ],
        Features: [
          { name: "Priority email support", included: true },
          { name: "Advanced monitoring", included: true },
          { name: "Enhanced security features", included: true },
        ],
      },
    },
    {
      name: "Enterprise",
      description: "For large-scale applications",
      price: 199,
      color: "#F59E0B",
      features: {
        Database: [
          { name: "Unlimited databases", included: true },
          { name: "Persistent Logs Retention", included: true },
          { name: "Custom optimization solutions", included: true },
        ],
        API: [
          { name: "Unlimited API calls", included: true },
          { name: "Dedicated API infrastructure", included: true },
          { name: "Custom API solutions", included: true },
        ],
        Features: [
          { name: "24/7 priority support", included: true },
          { name: "Custom monitoring solutions", included: true },
          { name: "Advanced security & compliance", included: true },
        ],
      },
    },
  ];

  return (
    <div className="text-text-primary py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-center mb-12 font-geist"
        >
          Tailored Plans for Every Stage
        </motion.h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-start">
          {plans.map((plan, index) => (
            <PlanCard key={plan.name} plan={plan} isPopular={index === 1} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
