import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addDatabase, removeDatabase } from "../../utils/apiFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import Button from "../Button";
import Input from "../Input";
import Select from "../Select";
import DatabaseCard from "./DatabaseCard";

const DatabaseForm = ({ enterpriseId, mutation, databaseTypes }) => {
  const [databaseType, setDatabaseType] = useState("");
  const [databaseName, setDatabaseName] = useState("");
  const [connString, setConnString] = useState("");
  const [useConnString, setUseConnString] = useState(false);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [database, setDatabase] = useState("");

  const handleToggle = () => {
    setUseConnString((prev) => !prev);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("useConnString:", useConnString);

    // Check if all fields are filled correctly
    if (!databaseType || !databaseName || (!connString && useConnString)) {
      toast.error("Please fill in all fields");
      return;
    }

    let finalConnString = connString;

    if (!useConnString) {
      console.log("user:", user);
      console.log("password:", password);
      console.log("host:", host);
      console.log("port:", port);
      console.log("database:", database);

      if (!user || !password || !host || !port || !database) {
        toast.error("Please fill in all fields");
        return;
      }

      // Construct the connection string from individual fields
      finalConnString = `postgresql://${user}:${encodeURIComponent(
        password
      )}@${host}:${port}/${database}??sslmode=disable`;
    }

    // Log the final connection string
    console.log("finalConnString:", finalConnString);

    mutation.mutate({
      enterprise_id: enterpriseId,
      database_type: databaseType,
      database_name: databaseName,
      conn_string: finalConnString,
    });

    setDatabaseType("");
    setDatabaseName("");
    setConnString("");
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="min-w-[300px] max-h-[600px] overflow-auto w-full max-w-md h-full p-6 flex flex-col bg-[#1A1A1A] text-white rounded-lg shadow-lg"
    >
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2">Add New Database</h2>
        <p className="text-sm text-[#AAAAAA]">
          Fill in the details below to add a new database.
        </p>
      </div>

      <div className="mb-2">
        <Select
          label="Database Type"
          value={databaseType}
          onChange={(e) => setDatabaseType(e.target.value)}
          options={databaseTypes}
          placeholder="Select Database Type"
        />
      </div>

      <div className="mb-6">
        <Input
          label="Database Name"
          value={databaseName}
          onChange={(e) => setDatabaseName(e.target.value)}
          placeholder="Database Name"
        />
      </div>

      <div className="mb-6">
        <motion.div
          className="flex items-center cursor-pointer"
          onClick={handleToggle}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="relative flex items-center">
            <input
              type="checkbox"
              checked={useConnString}
              onChange={handleToggle}
              className="hidden"
            />
            <div className="w-12 h-6 bg-[#2F2F2F] rounded-full shadow-inner flex items-center relative transition-colors duration-300">
              <motion.div
                className={`w-6 h-6 bg-[#0056C8] rounded-full shadow absolute transform transition-transform duration-300 ${
                  useConnString ? "translate-x-6 bg-[#10B981]" : "translate-x-0"
                }`}
                layout
              />
              <div
                className={`absolute left-1 top-1 w-4 h-4 rounded-full transition-opacity duration-300 ${
                  useConnString ? "opacity-0" : "opacity-100"
                }`}
              >
                <svg
                  className="w-full h-full text-[#0056B3]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M18 12H6"
                  />
                </svg>
              </div>
              <div
                className={`absolute right-1 top-1 w-4 h-4 rounded-full transition-opacity duration-300 ${
                  useConnString ? "opacity-100" : "opacity-0"
                }`}
              >
                <svg
                  className="w-full h-full text-[#FFFFFF]"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
          </div>
          <span className="ml-3 text-sm text-[#E5E7EB]">
            Use Connection String
          </span>
        </motion.div>
      </div>

      {useConnString ? (
        <div className="mb-6">
          <Input
            label="Connection String"
            value={connString}
            onChange={(e) => setConnString(e.target.value)}
            placeholder="Connection String"
          />
        </div>
      ) : (
        <>
          <div className="mb-6">
            <Input
              onChange={(e) => setUser(e.target.value)}
              value={user}
              label="User"
              name="user"
              placeholder="User"
            />
          </div>
          <div className="mb-6">
            <Input
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              name="password"
              value={password}
              type="password"
              placeholder="Password"
            />
          </div>
          <div className="mb-6">
            <Input
              value={host}
              onChange={(e) => setHost(e.target.value)}
              label="Host"
              name="host"
              placeholder="Host"
            />
          </div>
          <div className="mb-6">
            <Input
              value={port}
              onChange={(e) => setPort(e.target.value)}
              label="Port"
              name="port"
              placeholder="Port"
            />
          </div>
          <div className="mb-6">
            <Input
              value={database}
              onChange={(e) => setDatabase(e.target.value)}
              label="Database"
              name="database"
              placeholder="Database"
            />
          </div>
        </>
      )}

      <Button type="submit" variation="primary">
        Add Database
      </Button>
    </form>
  );
};

const Databases = ({ enterpriseDetails }) => {
  const [databaseFormOpen, setDatabaseFormOpen] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addDatabase,
    onSuccess: () => {
      queryClient.invalidateQueries(["enterpriseDetails"]);
      toast.success("Database added successfully");
    },
    onError: (error) => {
      console.log("help", error);
      toast.error(error.response.data.error || "Error adding database");
    },
  });

  const databaseTypes = [
    { value: "mysql", label: "MySQL" },
    { value: "postgres", label: "PostgreSQL" },
    { value: "mongodb", label: "MongoDB" },
  ];

  const handleDatabaseDelete = (databaseId) => {
    removeDatabase({ database_id: databaseId })
      .then(() => {
        queryClient.invalidateQueries(["enterpriseDetails"]);
        toast.success("Database removed successfully");
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || "Error removing database";
        toast.error(errorMessage);
      });
  };

  if (!enterpriseDetails) return null;

  return (
    <div className="w-full h-full p-8 flex flex-col">
      <div className="w-full flex flex-row items-center justify-between relative">
        <h1 className="text-white font-geist text-2xl">Databases</h1>
        <div>
          <Button onClick={() => setDatabaseFormOpen(!databaseFormOpen)}>
            <div className="w-full items-center flex flex-row justify-center gap-2">
              Add Database
              {databaseFormOpen ? (
                <FaChevronUp className="text-sm" />
              ) : (
                <FaChevronDown className="text-sm" />
              )}
            </div>
          </Button>
        </div>
        <AnimatePresence>
          {databaseFormOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="absolute top-12 z-20 right-0 bg-[#1A1A1A] border border-[#2F2F2F] rounded-lg shadow-sm shadow-black"
            >
              <DatabaseForm
                enterpriseId={enterpriseDetails.enterprise.enterprise_id}
                mutation={mutation}
                databaseTypes={databaseTypes}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="w-full h-full flex flex-row space-evenly mt-6">
        <div className="w-full h-full flex flex-row flex-wrap gap-4">
          {enterpriseDetails.databases.map((database) => (
            <div key={database.database_id}>
              <DatabaseCard
                database={database}
                logs={enterpriseDetails.logs}
                onDelete={(databaseId) => handleDatabaseDelete(databaseId)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Databases;
