import React, { useState } from "react";
import { FaLayerGroup, FaKey, FaEllipsisV, FaTrash } from "react-icons/fa";
import { BiLogoMongodb, BiLogoPostgresql } from "react-icons/bi";
import { TbBrandMysql } from "react-icons/tb";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment";
import Checkbox from "../Checkbox";

const DynamicKeyDashboard = () => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showPermissions, setShowPermissions] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const groups = [
    {
      group_id: 1,
      group_name: "Admin Group",
      database_name: "User_DB",
      database_type: "postgres",
      lastUsedTime: "2024-06-15T12:00:00Z",
    },
    {
      group_id: 2,
      group_name: "Read-Only Users",
      database_name: "Analytics_DB",
      database_type: "mongodb",
      lastUsedTime: "2024-06-20T14:30:00Z",
    },
  ];

  const availablePermissions = [
    { permission_id: 1, permission_name: "User Management" },
    { permission_id: 2, permission_name: "Log Access" },
    { permission_id: 3, permission_name: "View Analytics" },
  ];

  const selectedPermissions = [
    { permission_id: 1, permission_name: "User Management" },
  ];

  const handleSelectGroup = (groupId) => {
    setSelectedGroup(groupId);
    setShowPermissions(!showPermissions);
  };

  const getIcon = (database_type) => {
    switch (database_type) {
      case "postgres":
        return <BiLogoPostgresql className="text-md" />;
      case "mysql":
        return <TbBrandMysql className="text-md" />;
      case "mongodb":
        return <BiLogoMongodb className="text-md" />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full min-w-[800px] flex flex-col items-start justify-start">
      <div className="w-full flex flex-row bg-[#111011] text-[#AAAAAA] font-dm font-medium rounded-lg">
        <h1 className="w-[25%] py-3 px-6 hover:text-[#BBBBBD] transition-all transition-200">
          Name
        </h1>
      </div>
      {groups.map((group) => (
        <div key={group.group_id} className="w-full flex flex-col">
          <div
            className={`w-full flex flex-row bg-[#111011] border border-[#2F2F2F] mt-4 rounded-lg hover:bg-[#242324] items-center p-4 shadow-md ${
              selectedGroup === group.group_id ? "bg-[#242324]" : ""
            }`}
          >
            <div className="w-3/5 flex flex-row gap-5 items-center">
              <div className="w-6 h-6 bg-[#0056B3] outline outline-[#0056B3] hover:bg-[#0049A6] outline-offset-2 rounded-full flex items-center justify-center">
                <FaLayerGroup className="text-white text-xs" />
              </div>
              <div className="flex flex-row gap-4 items-center">
                <p className="text-white font-geist font-normal">
                  {group.group_name}
                </p>
                <span className="flex flex-row items-center justify-center gap-2 badge bg-transparent border border-[#0056B3] text-[#aaa] px-2 py-1 rounded-lg ml-3 text-xs">
                  {getIcon(group.database_type)} {group.database_name}
                </span>
              </div>
            </div>
            <div className="w-2/5 flex justify-end gap-2 items-center">
              <p className="text-[#898A8C] w-[50%] flex text-end justify-end font-geist font-light text-xs mr-4 mt-1">
                {`Last used on ${moment(group.lastUsedTime).format(
                  "MM/DD/YYYY"
                )}`}
              </p>
              <button
                onClick={() => {
                  /* Handle generate API key */
                }}
                className="p-2 text-white rounded-lg w-[50%] flex items-center hover:text-[#aaa]"
              >
                <FaKey className="mr-2 text-xs" />
                <span>Generate API Key</span>
              </button>
              <div className="relative">
                <button
                  onClick={() => handleSelectGroup(group.group_id)}
                  className={`p-2 text-white rounded-lg flex items-center hover:text-[#aaa] ${
                    isMenuOpen ? "bg-[#2F2F2F]" : "bg-transparent"
                  }`}
                >
                  <FaEllipsisV />
                </button>
              </div>
            </div>
          </div>
          <AnimatePresence>
            {showPermissions && selectedGroup === group.group_id && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="bg-[#1A1A1A] border border-[#2F2F2F] rounded-lg p-4 mt-2 shadow-md"
              >
                <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
                  <div className="w-full lg:w-1/2 flex flex-col">
                    <h3 className="text-md text-white font-medium mb-2">
                      Available Permissions
                    </h3>
                    <div className="flex flex-wrap">
                      {availablePermissions.length === 0 ? (
                        <p className="text-[#AAAAAA] italic font-light text-sm">
                          No permissions available
                        </p>
                      ) : (
                        availablePermissions.map((permission) => (
                          <label
                            key={permission.permission_id}
                            className="p-2 bg-[#232426] border border-[#2F2F2F] rounded-lg m-1 flex cursor-pointer items-center"
                          >
                            <Checkbox
                              label={permission.permission_name}
                              value={permission.permission_id}
                              checked={selectedPermissions.some(
                                (perm) =>
                                  perm.permission_id ===
                                  permission.permission_id
                              )}
                              onChange={() => {
                                /* Handle add permission */
                              }}
                            />
                          </label>
                        ))
                      )}
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 flex flex-col">
                    <h3 className="text-md text-white font-medium mb-2">
                      Enabled Permissions
                    </h3>
                    <div className="flex flex-wrap">
                      {selectedPermissions.length === 0 ? (
                        <p className="text-[#AAAAAA] italic font-light text-sm">
                          No permissions enabled
                        </p>
                      ) : (
                        selectedPermissions.map((permission) => (
                          <label
                            key={permission.permission_id}
                            className="p-2 bg-[#232426] border border-[#2F2F2F] rounded-lg m-1 flex items-center cursor-pointer"
                          >
                            <Checkbox
                              label={permission.permission_name}
                              value={permission.permission_id}
                              checked
                              onChange={() => {
                                /* Handle remove permission */
                              }}
                            />
                          </label>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ))}
    </div>
  );
};

export default DynamicKeyDashboard;
