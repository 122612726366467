import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ label, checked, onChange, value, disabled }) => {
  return (
    <label className={`flex items-center ${disabled ? "opacity-50" : ""}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        value={value}
        disabled={disabled}
        className="peer cursor-pointer hidden after:opacity-100"
        />
        <span
      className="inline-block w-4 h-4 border rounded-md border-[#0056B3] p-2 relative cursor-pointer after:content-[''] after:absolute after:top-2/4 after:left-2/4 after:-translate-x-1/2 after:-translate-y-1/2 after:w-[10px] after:h-[10px] after:bg-[#0056B3] after:rounded-[2px] after:opacity-0 peer-checked:after:opacity-100"
    ></span>
      <span className="ml-2 text-[#AAAAAA]">{label}</span>
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Checkbox;
