import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FiShield,
  FiKey,
  FiCheck,
  FiServer,
  FiTrendingUp,
  FiUserPlus,
  FiAward,
  FiDatabase,
} from "react-icons/fi";
import AdvantageCard from "./AdvantageCard";

const DataNode = ({ Icon, color, isActive, onClick }) => (
  <motion.div
    className="w-16 h-16 relative cursor-pointer"
    whileHover={{ scale: 1.1 }}
    animate={isActive ? { scale: 1.1 } : { scale: 1 }}
    onClick={onClick}
  >
    <svg width="64" height="64" viewBox="0 0 64 64">
      <defs>
        <filter id={`glow-${color}`}>
          <feGaussianBlur stdDeviation="2.5" result="coloredBlur" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <circle
        cx="32"
        cy="32"
        r="28"
        fill="#1A1A1A"
        stroke={color}
        strokeWidth="2"
      />
      <circle
        cx="32"
        cy="32"
        r="26"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeDasharray="4,2"
        filter={isActive ? `url(#glow-${color})` : "none"}
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 32 32"
          to="360 32 32"
          dur="10s"
          repeatCount="indefinite"
        />
      </circle>
      <foreignObject x="16" y="16" width="32" height="32">
        <div className="w-full h-full flex items-center justify-center">
          <Icon className="text-xl" style={{ color: color }} />
        </div>
      </foreignObject>
    </svg>
  </motion.div>
);

const AdvantageAnimations = () => {
  const [activeNode, setActiveNode] = useState(0);
  const [isAutoPlay, setIsAutoPlay] = useState(true);

  const dataNodes = [
    {
      icon: FiShield,
      label: "Unmatched Protection",
      color: "#0056C8",
      title: "Fortress-like Security",
      description:
        "ThirtyFirst creates a secure shield around your data, safeguarding your most valuable assets.",
      impact: [
        "Reduce the risk of data breaches",
        "Protect against both external threats and insider risks",
        "Ensure compliance with the strictest data protection regulations",
      ],
    },
    {
      icon: FiTrendingUp,
      label: "Accelerated Growth",
      color: "#00A3FF",
      title: "Turbocharge Your Innovation",
      description:
        "By simplifying security management, ThirtyFirst empowers your team to focus on innovation rather than constant firefighting.",
      impact: [
        "Speed up development cycles with built-in security",
        "Reduce time-to-market for new features and products",
        "Improve collaboration between security and development teams",
      ],
    },
    {
      icon: FiUserPlus,
      label: "Enhanced Trust",
      color: "#F59E0B",
      title: "Build Unshakeable Trust",
      description:
        "With ThirtyFirst, confidently assure your clients and partners that their data is protected.",
      impact: [
        "Increase customer confidence and loyalty",
        "Gain a competitive edge in security-conscious markets",
        "Simplify audits and compliance reporting",
      ],
    },
    {
      icon: FiAward,
      label: "Future-Ready",
      color: "#10B981",
      title: "Stay Ahead of the Curve",
      description:
        "ThirtyFirst's adaptive architecture ensures you're prepared for tomorrow's security challenges, not just today's.",
      impact: [
        "Continuously protect against evolving threats",
        "Scale seamlessly as your business grows",
        "Receive regular updates to counter new vulnerabilities",
      ],
    },
    {
      icon: FiKey,
      label: "Effortless Control",
      color: "#8B5CF6",
      title: "Simplify Complex Security",
      description: "Take control of your data security with ThirtyFirst's intuitive interfaces and automated processes.",
      impact: [
        "Reduce the learning curve for new team members",
        "Minimize human error in security operations",
        "Free up your team to focus on strategic initiatives",
      ],
    },
  ];

  useEffect(() => {
    let interval;
    if (isAutoPlay) {
      interval = setInterval(() => {
        setActiveNode((prevNode) => (prevNode + 1) % dataNodes.length);
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlay, dataNodes.length]);

  const handleNodeClick = useCallback((index) => {
    setActiveNode(index);
    setIsAutoPlay(false);
    setTimeout(() => setIsAutoPlay(true), 15000);
  }, []);

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, y: -20, transition: { duration: 0.5 } },
  };

  return (
    <div className="w-full min-h-[80vh] grid grid-cols-1 lg:grid-cols-5 gap-8 items-center mb-20">
      <div className="relative w-full h-full lg:col-span-3 flex items-center justify-center">
        <svg
          className="w-full h-full sm:max-h-[600px] lg:max-h-[800px]"
          viewBox="0 0 600 600"
          preserveAspectRatio="xMidYMid meet"
        >
          <defs>
            <pattern
              id="grid"
              width="30"
              height="30"
              patternUnits="userSpaceOnUse"
            >
              <path
                d="M 30 0 L 0 0 0 30"
                fill="none"
                stroke="#2A2A2A"
                strokeWidth="0.5"
              />
            </pattern>
          </defs>

          {/* Background elements */}
          <g>
            <rect width="600" height="600" fill="url(#grid)" />
            <circle
              cx="300"
              cy="300"
              r="280"
              fill="none"
              stroke="#2A2A2A"
              strokeWidth="1"
              strokeDasharray="5,5"
            />
            <circle
              cx="300"
              cy="300"
              r="200"
              fill="none"
              stroke="#2A2A2A"
              strokeWidth="1"
              strokeDasharray="3,3"
            />
          </g>

          {/* Server icon */}
          <g transform="translate(100, 300)">
            <rect
              x="-35"
              y="-45"
              width="70"
              height="90"
              fill="#1A1A1A"
              stroke="#0056C8"
              strokeWidth="2"
              rx="5"
            />
            <foreignObject x="-22" y="-32" width="44" height="64">
              <div className="w-full h-full flex flex-col items-center justify-center">
                <FiServer className="text-3xl text-primary mb-2" />
                <FiDatabase className="text-2xl text-primary" />
              </div>
            </foreignObject>
          </g>

          {/* Node icons and connections */}
          {dataNodes.map((node, index) => {
            const y = 100 + index * 100; // Adjusted spacing
            return (
              <g key={index}>
                {/* Connection line */}
                <motion.path
                  d={`M135,300 Q340,${y} 460,${y}`}
                  fill="none"
                  stroke={node.color}
                  strokeWidth="2"
                  strokeDasharray="5,5"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: activeNode === index ? 1 : 0 }}
                  transition={{ duration: 1 }}
                />

                {/* Animated dots */}
                {activeNode === index &&
                  [...Array(3)].map((_, i) => (
                    <motion.circle
                      key={i}
                      r="3"
                      fill={node.color}
                      style={{ mixBlendMode: "screen" }}
                    >
                      <animateMotion
                        dur="3s"
                        repeatCount="indefinite"
                        begin={`${i * 1}s`}
                        path={`M135,300 Q340,${y} 460,${y}`}
                      />
                    </motion.circle>
                  ))}

                {/* Node icon */}
                <foreignObject x="460" y={y - 32} width="64" height="64">
                  <DataNode
                    Icon={node.icon}
                    color={node.color}
                    isActive={activeNode === index}
                    onClick={() => handleNodeClick(index)}
                  />
                </foreignObject>
              </g>
            );
          })}
        </svg>
      </div>

      <div className="lg:col-span-2">
        <h2 className="font-geist text-2xl md:text-3xl font-bold mb-4 text-text-primary">
          Why ThirtyFirst Stands Out
        </h2>
        <p className="font-dm text-lg text-text-secondary mb-12">
          Experience unparalleled data security and streamlined operations with
          our innovative approach.
        </p>
        <AdvantageCard activeNode={activeNode} dataNodes={dataNodes} />
      </div>
    </div>
  );
};

export default AdvantageAnimations;
