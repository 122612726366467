import React, { useState } from "react";
import { FaKey, FaEye, FaPen, FaTrash, FaEllipsisV } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const AccessIcon = ({ icon, explanation, access }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`transition-colors duration-200 ${
          access
            ? isHovered
              ? "text-[#007BFF]"
              : "text-[#aaa]"
            : "text-[#333]"
        }`}
      >
        {icon}
      </div>
      <AnimatePresence>
        {isHovered && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute z-50 bg-[#1A1A1A] border border-[#3F3F3F] rounded-md p-2 text-xs text-white whitespace-nowrap"
            style={{ top: "100%", left: "50%", transform: "translateX(-50%)" }}
          >
            {explanation}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const AccessControlDashboard = () => {
  const permissions = [
    {
      permission_id: 1,
      permission_name: "User Data Access",
      data_type: "User",
      fields: ["name", "email", "role"],
      read_access: true,
      write_access: true,
      delete_access: false,
    },
    {
      permission_id: 2,
      permission_name: "Analytics View",
      data_type: "Analytics",
      fields: ["visits", "conversions"],
      read_access: true,
      write_access: false,
      delete_access: false,
    },
  ];

  return (
    <div className="w-full min-w-[800px] flex flex-col items-start justify-start">
        <div className="w-full flex flex-row bg-[#111011] text-[#AAAAAA] font-dm font-medium rounded-lg ">
              <h1 className="w-[25%] py-3 px-6 hover:text-[#BBBBBD] transition-all transition-200">
                Name
              </h1>
              <h1 className="w-[20%] py-3 hover:text-[#BBBBBD] transition-all transition-200">
                Access Level
              </h1>
              <h1 className="w-[20%] py-3 hover:text-[#BBBBBD] transition-all transition-200">
                Data Type
              </h1>
              <h1 className="w-[35%] py-3 hover:text-[#BBBBBD] transition-all transition-200">
                Fields
              </h1>
            </div>
      {permissions.map((permission) => (
        <div
          key={permission.permission_id}
          className="w-full flex flex-row bg-[#111011] border-[#2F2F2F] border mt-4 rounded-xl hover:bg-[#242324] items-center p-4"
        >
          <div className="w-[25%] flex flex-row gap-4 items-center">
            <div className="w-6 h-6 bg-[#0056B3] outline outline-[#0056B3] hover:bg-[#0049A6] outline-offset-2 rounded-full flex items-center justify-center">
              <FaKey className="text-white text-xs" />
            </div>
            <p className="text-white font-geist font-normal">
              {permission.permission_name}
            </p>
          </div>
          <div className="w-[20%] flex items-center gap-2">
            <div className="text-sm py-2 px-2 rounded flex gap-3">
              {permission.read_access && (
                <AccessIcon
                  icon={<FaEye />}
                  explanation="Read Access"
                  access={permission.read_access}
                />
              )}
              {permission.write_access && (
                <AccessIcon
                  icon={<FaPen />}
                  explanation="Write Access"
                  access={permission.write_access}
                />
              )}
              {permission.delete_access && (
                <AccessIcon
                  icon={<FaTrash />}
                  explanation="Delete Access"
                  access={permission.delete_access}
                />
              )}
            </div>
          </div>
          <div className="w-[20%]">
            <p className="text-white font-geist font-normal text-sm">
              {permission.data_type[0].toUpperCase() +
                permission.data_type.slice(1)}
            </p>
          </div>
          <div className="w-[30%]">
            <p className="text-[#7F7F7F] font-geist font-normal text-sm flex flex-row flex-wrap gap-1">
              {permission.fields.map((field) => (
                <span
                  key={field}
                  className={`inline-block bg-[#111111] py-1 px-2 rounded-md hover:text-[#007BFF]`}
                >
                  {field[0].toUpperCase() + field.slice(1)}
                </span>
              ))}
            </p>
          </div>
          <div className="relative w-[5%]">
            <button
              className={`p-2 ml-auto text-white rounded-lg flex items-center hover:text-[#a9a9a9]`}
            >
              <FaEllipsisV />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccessControlDashboard;
