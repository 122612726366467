import React, { useEffect, useState } from "react";
import {
  FaBan,
  FaShieldAlt,
  FaExclamationTriangle,
  FaInfoCircle,
  FaNetworkWired,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";
import Button from "../Button";
import Modal from "../Modal";
import Textarea from "../TextArea";
import Input from "../Input";
import Toggle from "../Toggle";
import moment from "moment-timezone";
import axios from "axios";
import Checkbox from "../Checkbox";
import { updateDatabaseAccessMode } from "../../utils/apiFunctions";
import SuspiciousQueryItem from "./SuspiciousQueryItem";
import generateRandomCode from "../../utils/generateCode";

const ActionCard = ({
  title,
  id,
  description,
  icon,
  color,
  details,
  activeCard,
  setActiveCard,
  enterpriseDetails,
  recentSuspiciousActivities,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState(
    generateRandomCode()
  );
  const [inputCode, setInputCode] = useState("");
  const [reason, setReason] = useState("");
  const [selectedDatabases, setSelectedDatabases] = useState({});
  const [initialToggleState, setInitialToggleState] = useState(false);

  useEffect(() => {
    if (showModal) {
      setConfirmationCode(generateRandomCode());
    }
  }, [showModal]);

  useEffect(() => {
    const activeDatabases = enterpriseDetails.databases.filter((db) =>
      id === "cutoff" ? db.access_cutoff : db.isolation_mode
    );

    if (activeDatabases.length > 0) {
      setInitialToggleState(true);
      setReason(activeDatabases[0].reason);
      const activeDatabaseIds = activeDatabases.reduce((acc, db) => {
        acc[db.database_id] = true;
        return acc;
      }, {});
      setSelectedDatabases(activeDatabaseIds);
    }
  }, [enterpriseDetails, id]);

  const handleConfirm = async () => {
    if (inputCode === confirmationCode) {
      const databaseIds = Object.keys(selectedDatabases).filter(
        (key) => selectedDatabases[key]
      );
      const response = await updateDatabaseAccessMode(id, databaseIds, reason);
      if (response.error) {
        toast.error(response.error);
      } else {
        setActiveCard(id);
        setInitialToggleState(true);
        toast.success(`${title} has been activated`);
      }
      setShowModal(false);
    } else {
      toast.error("Confirmation code is incorrect.");
    }
  };

  const handleToggleClick = async () => {
    if (activeCard === id || initialToggleState) {
      const databaseIds = Object.keys(selectedDatabases).filter(
        (key) => selectedDatabases[key]
      );
      const response = await updateDatabaseAccessMode(id, databaseIds, "");
      if (response.error) {
        toast.error(response.error);
      } else {
        setActiveCard("");
        setInitialToggleState(false);
        setReason("");
        toast.success(`${title} has been deactivated`);
      }
    } else {
      setShowModal(true);
    }
  };

  const handleCheckboxChange = (event) => {
    setSelectedDatabases({
      ...selectedDatabases,
      [event.target.value]: event.target.checked,
    });
  };

  const calculateTotalAnomalies = () => {
    return recentSuspiciousActivities.reduce(
      (acc, log) => acc + log.anomaly_score,
      0
    );
  };

  const calculateAnomalyPercentage = (databaseId, totalAnomalies) => {
    const logs = recentSuspiciousActivities.filter(
      (log) => log.database_id === databaseId
    );
    const totalScore = logs.reduce((sum, log) => sum + log.anomaly_score, 0);
    return totalAnomalies > 0
      ? Math.round((totalScore / totalAnomalies) * 100)
      : 0;
  };

  const totalAnomalies = calculateTotalAnomalies();

  const databaseOptions = enterpriseDetails.databases.map((db) => ({
    value: db.database_id.toString(),
    percentage: calculateAnomalyPercentage(db.database_id, totalAnomalies),
    label: `${db.database_name}`,
  }));

  return (
    <>
      <div
        className={`w-full flex flex-col bg-[#111011] border border-[#2F2F2F] mt-4 rounded-lg p-4 shadow-md ${
          activeCard === ""
            ? ""
            : activeCard === id
            ? ""
            : "opacity-50 cursor-not-allowed"
        }`}
      >
        <div className="flex flex-row w-full items-center justify-between mb-4">
          <div className="flex items-center">
            <div
              className={`min-w-10 min-h-10 max-w-10 max-h-10 w-10 h-10 rounded-full bg-gradient-to-br from-${color}-500 to-${color}-600 flex items-center justify-center mr-4`}
            >
              {icon}
            </div>
            <h3 className="text-xl font-bold text-white">{title}</h3>
          </div>
          <Toggle
            checked={activeCard === id || initialToggleState}
            onChange={handleToggleClick}
            disabled={activeCard !== "" && activeCard !== id}
          />
        </div>
        <p className="text-gray-300 mb-4">{description}</p>
        <AnimatePresence>
          {(activeCard === id || initialToggleState) && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="mt-4 bg-[#2A2A2A] p-4 rounded-lg">
                <h4 className="text-white font-semibold mb-2 flex items-center">
                  <FaInfoCircle className="mr-2" />
                  Active Functionalities:
                </h4>
                <ul className="list-disc list-inside text-gray-300 space-y-2">
                  {details.map((detail, index) => (
                    <li key={index} className="mb-1 ml-2 text-base font-medium">
                      {detail}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        {(activeCard === id || initialToggleState) && (
          <p className="text-yellow-500 mt-2 text-sm">{reason}</p>
        )}
      </div>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          title={`Confirm ${title}`}
          subtitle={`Type the code to proceed`}
          icon={icon}
        >
          <div className="flex flex-col w-full h-full pt-2 items-center justify-start gap-4">
            <h2 className="text-blue-500 font-semibold text-lg mb-2">
              {confirmationCode}
            </h2>
            <Input
              type="text"
              label="Confirmation code"
              placeholder="Enter the confirmation code"
              value={inputCode}
              onChange={(e) => setInputCode(e.target.value)}
            />
            <div className="w-full">
              <p className="text-[#AAAAAA] text-sm font-medium font-dm mb-2">
                Select databases
              </p>
              {databaseOptions.map((option) => (
                <div
                  key={option.value}
                  className="w-full flex flex-row justify-between items-center mb-2"
                >
                  <Checkbox
                    label={option.label}
                    value={option.value}
                    checked={selectedDatabases[option.value] || false}
                    onChange={handleCheckboxChange}
                  />
                  <div className="flex flex-col items-center">
                    <p className="text-gray-500 text-xs">
                      {option.percentage}% of total anomalies
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <Textarea
              label={"Reason for this action"}
              placeholder="Reason for this action"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              style={{ maxHeight: "150px", overflowY: "auto" }}
            />
            <Button
              onClick={handleConfirm}
              variation="primary"
              className="mt-4"
              disabled={
                !Object.values(selectedDatabases).includes(true) ||
                inputCode !== confirmationCode ||
                reason === ""
              }
            >
              Confirm
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};


const PreviousBreachItem = ({ breach }) => (
  <div className="w-full flex items-center flex-row bg-[#111011] border border-[#2F2F2F] mt-4 rounded-lg p-4 shadow-md">
    <div className="min-w-10 min-h-10 max-h-10 max-w-10 w-10 h-10 rounded-full bg-[#0056C8] outline-[#0056C8] outline outline-offset-2 flex items-center justify-center mr-4">
      <FaNetworkWired className="text-white text-xl" />
    </div>
    <div className="flex flex-col justify-center">
      <p className="text-white text-sm font-semibold">
        {new Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }).format(new Date(breach.date))}
      </p>
      <p className="text-gray-400 text-xs mt-1">
        <span className="text-gray-300 text-xs">{breach.action}</span>
      </p>
    </div>
    <div className="ml-auto text-right">
      <p className="text-gray-400 text-xs">Anomaly Score</p>
      <p className="text-white font-medium">
        {(breach.avgAnomalyScore * 100).toFixed(2)}
      </p>
    </div>
  </div>
);

const ActiveResponse = ({ recentSuspiciousActivities, enterpriseDetails }) => {
  const [activeCard, setActiveCard] = useState("");

  useEffect(() => {
    const isCutoffActive = enterpriseDetails.databases.some(
      (db) => db.access_cutoff
    );
    const isIsolationActive = enterpriseDetails.databases.some(
      (db) => db.isolation_mode
    );

    if (isCutoffActive) {
      setActiveCard("cutoff");
    } else if (isIsolationActive) {
      setActiveCard("isolation");
    } else {
      setActiveCard("");
    }
  }, [enterpriseDetails]);

  const previousBreaches = [
    {
      date: "2022-01-01",
      action: "Access Cutoff",
      avgAnomalyScore: 0.5,
    },
    {
      date: "2022-02-01",
      action: "Isolation Mode",
      avgAnomalyScore: 0.8,
    },
  ];

  return (
    <div className="w-full">
      <div className="flex flex-col gap-8">
        <div className="grid grid-cols-2 gap-6">
          <ActionCard
            title="Access Cutoff"
            id="cutoff"
            key="cutoff"
            description="Emergency measure to prevent all access to the system."
            icon={<FaBan className="text-white text-2xl" />}
            color="red"
            details={[
                "Block all access to the database",
                "Prevent all read, write, and delete operations",
            ]}
            activeCard={activeCard}
            setActiveCard={setActiveCard}
            enterpriseDetails={enterpriseDetails}
            recentSuspiciousActivities={recentSuspiciousActivities}
          />
          <ActionCard
            title="Isolation Mode"
            id="isolation"
            key="isolation"
            description="Restrict access to critical components while maintaining essential operations."
            icon={<FaShieldAlt className="text-white text-2xl" />}
            color="yellow"
            details={[
                "Allow only read operations",
                "Block all write and delete operations",
            ]}
            activeCard={activeCard}
            setActiveCard={setActiveCard}
            enterpriseDetails={enterpriseDetails}
            recentSuspiciousActivities={recentSuspiciousActivities}
          />
        </div>

        <div className="grid grid-cols-5 gap-6">
          <div className="col-span-5 bg-[#111011] border border-[#2F2F2F] p-6 rounded-xl shadow-lg">
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-xl font-medium text-white flex items-center">
                Recent Suspicious Queries
              </h3>
            </div>
            <div className="space-y-4 max-h-96 overflow-y-auto pr-4 custom-scrollbar">
              {recentSuspiciousActivities.length === 0 && (
                <p className="text-gray-400 text-sm">No suspicious queries found.</p>
              )}{" "}
              {recentSuspiciousActivities.map((activity, index) => (
                <SuspiciousQueryItem key={index} activity={activity} />
              ))}
            </div>
          </div>

          {/* <div className="bg-[#111011] col-span-2 border border-[#2F2F2F] p-6 rounded-xl shadow-lg">
            <h3 className="text-xl font-medium text-white flex items-center mb-2">
              Previous Breaches
            </h3>
            <div className="space-y-4">
              {previousBreaches.map((breach, index) => (
                <PreviousBreachItem key={index} breach={breach} />
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ActiveResponse;
