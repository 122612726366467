import React, { useState, useRef, useEffect } from "react";
import { BiLogoMongodb, BiLogoPostgresql } from "react-icons/bi";
import { TbBrandMysql } from "react-icons/tb";
import { FaTrash, FaEllipsisV } from "react-icons/fa";
import DatabaseQueryChart from "./DatabaseQueryChart";
import { motion, AnimatePresence } from "framer-motion";
import Modal from "../Modal";
import Button from "../Button";
import Input from "../Input";
import { toast } from "react-toastify";
import generateRandomCode from "../../utils/generateCode";

function DatabaseCard({ database, logs, onDelete }) {
  const { database_name, database_type, database_id } = database;
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState(
    generateRandomCode()
  );
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  const [inputCode, setInputCode] = useState("");
  const menuRef = useRef(null);
  const cardRef = useRef(null);

  let type = "";
  let icon = "";

  switch (database_type) {
    case "postgres":
      icon = (
        <BiLogoPostgresql className="text-[#0056C8] text-lg outline outline-[#0056B3] rounded-full outline-offset-2" />
      );
      type = "PostgreSQL";
      break;
    case "mysql":
      icon = (
        <TbBrandMysql className="text-[#0056C8] text-lg outline outline-[#0056B3] rounded-full outline-offset-2" />
      );
      type = "MySQL";
      break;
    case "mongodb":
      icon = (
        <BiLogoMongodb className="text-[#0056C8] text-lg outline outline-[#0056B3] rounded-full outline-offset-2" />
      );
      type = "MongoDB";
      break;
    default:
      icon = "Unknown";
      type = "Unknown";
      break;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleContextMenu = (e) => {
    e.preventDefault();
    const rect = cardRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const adjustedX = Math.min(x, rect.width - 150);
    const adjustedY = Math.min(y, rect.height - 50);

    setMenuPosition({ x: adjustedX, y: adjustedY });
    setShowMenu(true);
  };

  const handleTouchStart = () => {
    const longPressTimer = setTimeout(() => {
      const rect = cardRef.current.getBoundingClientRect();
      setMenuPosition({
        x: rect.width - 160,
        y: 10,
      });
      setShowMenu(true);
    }, 500);

    return () => clearTimeout(longPressTimer);
  };

  const handleDelete = () => {
    onDelete(database_id);
    setShowMenu(false);
  };

  const handleModalConfirm = () => {
    if (inputCode === confirmationCode) {
      handleDelete();
      toast.success("Database removed successfully");
    } else {
      toast.error("Confirmation code is incorrect.");
    }
    setShowModal(false);
  };

  return (
    <>
      <motion.div
        ref={cardRef}
        onContextMenu={handleContextMenu}
        onTouchStart={handleTouchStart}
        onTouchEnd={() => clearTimeout(handleTouchStart())}
        className="w-[325px] py-4 bg-[#111011] border-[#2F2F2F] border rounded-lg overflow-visible relative group"
        whileHover={{ scale: 1.02 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <div className="w-full h-full flex flex-col">
          <DatabaseQueryChart
            logs={logs}
            databaseName={database_name}
            database_id={database_id}
          />
          <div className="w-full px-6 flex flex-col gap-2 pb-4">
            <h1 className="font-dm text-white font-medium text-lg">
              {database_name}
            </h1>
            <div className="w-full flex flex-row gap-6 pt-2">
              <div className="flex flex-row gap-3 items-center">
                {icon}
                <p className="text-gray-200 font-extralight font-dm text-sm">
                  {type[0].toUpperCase() + type.slice(1)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <motion.div
          className="absolute top-2 right-2 text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
          whileHover={{ scale: 1.2 }}
          onClick={() => setShowMenu(!showMenu)}
        >
          <FaEllipsisV />
        </motion.div>
        <AnimatePresence>
          {showMenu && (
            <motion.div
              ref={menuRef}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.2 }}
              style={{
                position: "absolute",
                top: `${menuPosition.y}px`,
                left: `${menuPosition.x}px`,
              }}
              className="bg-[#1A1A1A] border border-[#3F3F3F] rounded-md shadow-lg z-50"
            >
              <motion.button
                onClick={() => setShowModal(true)}
                className="flex items-center w-full text-left px-4 py-2 text-sm text-white hover:bg-[#2F2F2F] transition-colors duration-150 ease-in-out"
                whileHover={{ backgroundColor: "#2F2F2F" }}
              >
                <FaTrash className="mr-2 text-red-500" /> Delete
              </motion.button>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          title="Confirm Database Deletion"
          subtitle={`Type the code to proceed`}
          icon={<FaTrash />}
        >
          <div className="flex flex-col w-full h-full pt-2 items-center justify-start gap-4">
            <h2 className="text-blue-500 font-semibold text-lg mb-2">
              {confirmationCode}
            </h2>
            <Input
              type="text"
              label="Confirmation code"
              placeholder="Enter the confirmation code"
              value={inputCode}
              onChange={(e) => setInputCode(e.target.value)}
            />
            <div className="w-full flex mt-2 mb-4 flex-col">
            <p className="text-[#AAAAAA] text-sm font-medium font-dm mb-2">
              The following items will be deleted along with the database:
            </p>
            <ul className="list-disc list-inside text-[#AAAAAA]">
              <li>Associated Permission Groups</li>
              <li>API Keys</li>
              <li>Permission Group Permissions</li>
              <li>Logs</li>
            </ul>
            </div>
            <div className="flex gap-4 w-full mt-4">
              <Button onClick={handleModalConfirm} variation="primary">
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default DatabaseCard;
