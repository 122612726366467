import { toast } from "react-toastify";
import api from "./api";

const login = async (email, password) => {
  try {
    const response = await api.post("/user/login", {
      email: email,
      password: password,
    });
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    return { error: "Error logging in. Please try again." };
  }
};

const signup = async (email, password) => {
  try {
    const response = await api.post("/user/register", {
      email: email,
      password: password,
    });
    return response.data;
  } catch (error) {
    console.error("Error signing up:", error);
    return { error: "Error signing up. Please try again." };
  }
};

const getEnterprises = async () => {
  const response = await api.get("/enterprise/all");
  return response.data;
};

const createEnterprise = async (data) => {
  console.log("creating enterprise", data);
  const response = await api.post("/enterprise/create", {
    enterprise_name: data.enterprise_name,
    type: data.type,
  });
  return response.data;
};

const getEnterpriseDetails = async (enterpriseName) => {
  console.log("getting enterprise details", enterpriseName);
  const response = await api.get(`/enterprise/${enterpriseName}/details`);
  console.log("enterprise details", response.data);
  return response.data;
};

const addDatabase = async (data) => {
  try {
    const response = await api.post("/database/add", {
      enterpriseId: data.enterprise_id,
      databaseType: data.database_type,
      databaseName: data.database_name,
      connString: data.conn_string,
    });
    return response.data;
  } catch (error) {
    let errorOutput = JSON.parse(error.response.request.response);
    console.log(errorOutput.message);
    toast.error(errorOutput.message || 'An error occurred while adding the database. Please try again.');
    throw errorOutput.message || "Error adding database";
  }
};

const addUserToEnterprise = async (data) => {
  const response = await api.post("/enterprise/add-user", data);
  return response.data;
};

const addPermission = async (data) => {
  const response = await api.post("/enterprise/add-permission", data);
  return response.data;
};

const fetchDatabaseSchema = async (data) => {
  console.log("fetchDatabaseSchema", data);
  const response = await api.post("/database/fetch-schema", {
    database_id: data,
  });

  console.log("fetchDatabaseSchema response", response.data);
  return response.data;
};

const addPermissionGroup = async (data) => {
  console.log("addPermissionGroup", data);

  // wait 5 seconds
  await new Promise((resolve) => setTimeout(resolve, 5000));

  const response = await api.post("/enterprise/add-permission-group", data);
  return response.data;
};

const regenerateApiKey = async (data) => {
  const response = await api.post("/enterprise/regenerate-api-key", data);
  return response.data;
};

const addPermissionToGroup = async (data) => {
  const response = await api.post("/enterprise/add-permission-to-group", data);
  return response.data;
};

const removePermissionFromGroup = async (data) => {
  const response = await api.post(
    "/enterprise/remove-permission-from-group",
    data
  );
  return response.data;
};

const updateDatabaseAccessMode = async (mode, databaseIds, reason) => {
  let updatedMode = mode === "cutoff" ? "access_cutoff" : "isolation_mode";

  console.log(
    "mode",
    updatedMode,
    "databaseIds",
    databaseIds,
    "reason",
    reason
  );

  try {
    const response = await api.post(`/database/update-access-mode`, {
      mode: updatedMode,
      databaseIds,
      reason,
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating database access mode:`, error);
    return { error: `Error updating database access mode. Please try again.` };
  }
};

const removeDatabase = async (data) => {
  const response = await api.post("/database/remove", data);
  return response.data;
};

const removeUserFromEnterprise = async (data) => {
  console.log("removeUserFromEnterprise", data);
  const response = await api.post("/enterprise/remove-user", data);
  return response.data;
};

const changeUserRole = async (data) => {
  console.log("changeUserRole", data);
  const response = await api.post("/enterprise/change-user-role", data);
  return response.data;
};

const removePermission = async (data) => {
  console.log("removePermission", data);
  const response = await api.post('/enterprise/remove', data);
  return response.data;
};

const deletePermissionGroup = async (data) => {
  const response = await api.post("/enterprise/delete-permission-group", data);
  return response.data;
};

const deleteEnterprise = async (data) => {
  const response = await api.post("/enterprise/delete", data);
  return response.data;
};

export {
  login,
  signup,
  getEnterprises,
  createEnterprise,
  getEnterpriseDetails,
  removeDatabase,
  addDatabase,
  addUserToEnterprise,
  addPermission,
  fetchDatabaseSchema,
  addPermissionGroup,
  regenerateApiKey,
  addPermissionToGroup,
  removePermissionFromGroup,
  deletePermissionGroup,
  updateDatabaseAccessMode,
  removeUserFromEnterprise,
  changeUserRole,
  removePermission,
  deleteEnterprise,
};
