import React from "react";
import { FaChevronDown, FaChevronUp, FaFileAlt } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment-timezone";

const LogRow = ({ log, onClick, isOpen }) => {
  let color = log.status === "success" ? "text-green-600" : "text-red-600";
  let background =
    log.status === "success"
      ? "bg-green-500 outline-green-500 hover:bg-green-600"
      : "bg-red-500 outline-red-500 hover:bg-red-600";
  let border = log.status === "success" ? "border-[#2F2F2F]" : "border-red-500";

  const localExecutionTime = moment(log.execution_time).format(
    "MMM D, YYYY h:mm A"
  );

  return (
    <div
      className={`w-full flex flex-col ${border} bg-[#111011] border mt-4 rounded-xl hover:bg-[#242324] items-center p-4 cursor-pointer`}
      onClick={onClick}
    >
      <div className="w-full flex flex-row items-center justify-between">
        <div className="w-[20%] flex flex-row gap-4 items-center">
          <div
            className={`min-w-6 min-h-6 w-6 h-6 ${background} outline outline-offset-2 rounded-full flex items-center justify-center`}
          >
            <FaFileAlt className="text-white text-xs" />
          </div>
          <p className="text-white font-geist font-normal">{log.group_name}</p>
        </div>
        <div className="w-[40%] truncate text-[#7F7F7F]">{log.query}</div>
        <div className="w-[25%] pl-4 text-white font-geist font-normal">
          {localExecutionTime}
        </div>
        <div className={`w-[15%] pl-4 font-geist font-normal ${color}`}>
          {log.status}
        </div>
        <button className="p-2 w-[5%] justify-end text-white rounded-lg flex items-center hover:text-[#a9a9a9]">
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="w-full bg-[#2F2F2F] text-[#AAAAAA] mt-4 p-4 rounded-b-lg"
          >
            <p>
              <strong>Execution Time:</strong> {localExecutionTime}
            </p>
            <p>
              <strong>User IP:</strong> {log.user_ip}
            </p>
            <p>
              <strong>Error Message:</strong> {log.error_message || "N/A"}
            </p>
            <p>
              <strong>Anomaly Score:</strong>{" "}
              {log.anomaly_score ? log.anomaly_score.toFixed(2) : "N/A"}
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LogRow;
