import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Input from "../Input";
import Button from "../Button";
import { loginSuccess } from "../../redux/slices/authSlice";
import { signup } from "../../utils/apiFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import generateUserUrlSegment from "../../utils/urlSegment";
import { motion } from "framer-motion";
import { FiMail, FiLock, FiCheckCircle } from "react-icons/fi";

function Signup() {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignup = async (e) => {
    e.preventDefault();
    if (userData.password !== userData.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    setLoading(true);
    try {
      const response = await signup(userData.email, userData.password);
      const { user, token } = response;
      dispatch(loginSuccess({ user, token }));
      navigate(`/dashboard/${generateUserUrlSegment(user.email)}`);
    } catch (err) {
      console.error(err);
      toast.error("Error signing up. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="w-full max-w-md mx-auto"
    >
      <form className="space-y-6 pt-8" onSubmit={handleSignup}>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1, duration: 0.5 }}
        >
          <Input
            label="Email Address"
            value={userData.email}
            type="email"
            onChange={(e) => setUserData({ ...userData, email: e.target.value })}
            placeholder="Enter your email"
            icon={<FiMail className="text-gray-400" />}
          />
        </motion.div>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Input
            label="Password"
            value={userData.password}
            type="password"
            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
            placeholder="Enter your password"
            icon={<FiLock className="text-gray-400" />}
          />
        </motion.div>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <Input
            label="Confirm Password"
            value={userData.confirmPassword}
            type="password"
            onChange={(e) => setUserData({ ...userData, confirmPassword: e.target.value })}
            placeholder="Confirm your password"
            icon={<FiCheckCircle className="text-gray-400" />}
          />
        </motion.div>
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <Button
            type="submit"
            loading={loading}
            variation="primary"
            className="w-full py-3 transition-all duration-300 transform hover:scale-105"
          >
            Sign Up
          </Button>
        </motion.div>
      </form>
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        className="mt-6 text-center"
      >
        <p className="text-white font-dm">
          Already have an account?{" "}
          <a
            className="text-blue-500 hover:underline hover:underline-offset-4 transition-all duration-300"
            href="/login"
          >
            Login
          </a>
        </p>
      </motion.div>
    </motion.div>
  );
}

export default Signup;