import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import LogRow from "./LogRow";

const Logging = ({ enterpriseDetails }) => {
  const [logs, setLogs] = useState([]);
  const [logDetails, setLogDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [inputPage, setInputPage] = useState("");
  const logsPerPage = 10;

  useEffect(() => {
    if (enterpriseDetails && enterpriseDetails.logs) {
      const sortedLogs = enterpriseDetails.logs.sort(
        (a, b) => new Date(b.execution_time) - new Date(a.execution_time)
      );
      setLogs(sortedLogs);
    }
  }, [enterpriseDetails]);

  const handleLogClick = (log) => {
    setLogDetails(logDetails?.log_id === log.log_id ? null : log);
  };

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = logs.slice(indexOfFirstLog, indexOfLastLog);
  const totalPages = Math.ceil(logs.length / logsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(Math.min(Math.max(1, pageNumber), totalPages));
  };

  const handleInputChange = (e) => {
    setInputPage(e.target.value);
  };

  const handleInputSubmit = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(inputPage, 10);
    if (!isNaN(pageNumber)) {
      if (pageNumber <= 0) {
        paginate(1);
      } else if (pageNumber > totalPages) {
        paginate(totalPages);
      } else {
        paginate(pageNumber);
      }
    }
    setIsDropdownOpen(false);
    setInputPage("");
  };

  if (!enterpriseDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full h-full p-8 flex flex-col">
      <div className="w-full flex flex-row items-center justify-between relative mb-6">
        <h1 className="text-white font-geist text-2xl">Logs</h1>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="p-2 text-white disabled:opacity-50 disabled:cursor-not-allowed hover:text-[#999999] transition-colors duration-200"
          >
            <FiChevronLeft size={20} />
          </button>
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="px-3 py-1 text-white hover:text-[#999999] flex items-center transition-colors duration-200"
            >
              {currentPage} / {totalPages}
            </button>
            {isDropdownOpen && (
              <div className="absolute top-full mt-1 right-0 bg-[#232426] rounded-lg shadow-lg p-3 border border-[#2F2F2F]">
                <form onSubmit={handleInputSubmit} className="flex items-center">
                  <input
                    type="number"
                    min="1"
                    max={totalPages}
                    value={inputPage}
                    onChange={handleInputChange}
                    className="w-24 px-3 py-2 app rounded-lg bg-[#232426] text-[#E5E7EB] border border-[#2F2F2F] focus:outline-none focus:border-[#3F3F3F] font-dm text-sm"
                    placeholder="Page #"
                  />
                  <button
                    type="submit"
                    className="ml-2 px-3 py-2 rounded-lg bg-[#3F3F3F] text-white hover:bg-[#4F4F4F] transition-colors duration-200 font-dm text-sm"
                  >
                    Go
                  </button>
                </form>
              </div>
            )}
          </div>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="p-2 text-white disabled:opacity-50 disabled:cursor-not-allowed hover:text-[#999999] transition-colors duration-200"
          >
            <FiChevronRight size={20} />
          </button>
        </div>
      </div>
      <div className="overflow-auto lg:overflow-visible flex flex-col pb-6">
        <div className="w-full flex flex-row justify-between bg-[#1A1A1A] text-[#AAAAAA] font-dm font-medium rounded-lg">
          <h1 className="w-[20%] py-3 px-6 hover:text-[#BBBBBD] transition-all duration-200">Group</h1>
          <h1 className="w-[40%] py-3 hover:text-[#BBBBBD] transition-all duration-200">Query</h1>
          <h1 className="w-[25%] py-3 hover:text-[#BBBBBD] transition-all duration-200">Execution Time</h1>
          <h1 className="w-[15%] py-3 hover:text-[#BBBBBD] transition-all duration-200">Status</h1>
          <div className="p-2 w-[5%]"/>
        </div>
        {currentLogs.map((log) => (
          <LogRow
            key={log.log_id}
            log={log}
            onClick={() => handleLogClick(log)}
            isOpen={logDetails?.log_id === log.log_id}
          />
        ))}
      </div>
      {/* Once lay toast here */}
    </div>
  );
};

export default Logging;
