import React from "react";
import { motion } from "framer-motion";
import { FaExclamationTriangle, FaLock, FaShieldAlt } from "react-icons/fa";

const ActiveBreachAlert = ({ activeBreaches }) => {
  if (!activeBreaches || activeBreaches.length === 0) return null;

  const breach = activeBreaches[0]; // Assuming we show details of the first breach

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="w-full mb-6"
    >
      <div className="bg-[#1A1A1A] h-full w-full items-center justify-start flex flex-row rounded-lg shadow-lg overflow-hidden">
        <div className="min-w-2 min-h-full bg-[#EF4444]" />
        <div className="min-w-[2px] ml-1 min-h-full bg-[#EF4444]" />

        <div className="px-6 py-4">
          <div className="flex flex-col items-start justify-between mb-3">
            <div className="flex items-center">
              <FaExclamationTriangle className="text-[#EF4444] text-xl mr-3" />
              <h3 className="font-bold text-lg text-white">Active Breach</h3>
            </div>
            <span className="text-[#EF4444] text-sm font-semibold">
              {breach.access_cutoff ? "Access Cutoff" : "Isolation Mode"}
            </span>
          </div>
          <div className="w-full flex flex-col">
            <p className="font-medium text-xs text-[#9CA3AF]">Reason</p>
            <p className="text-[#E5E7EB] text-sm mb-3">{breach.reason}</p>
          </div>
          <div className="w-full flex flex-col">
            <p className="font-medium text-xs mb-1 text-[#9CA3AF]">
              Affected Databases
            </p>

            <ul className="list-disc list-inside text-[#E5E7EB] text-sm pl-2">
              {activeBreaches.map((db) => (
                <li key={db.database_id}>{db.database_name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ActiveBreachAlert;
