import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  addPermissionGroup,
  regenerateApiKey,
  addPermissionToGroup,
  removePermissionFromGroup,
  deletePermissionGroup,
} from "../../utils/apiFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import PermissionGroupRow from "./PermissionGroupRow";
import Button from "../Button";
import Input from "../Input";
import Select from "../Select";
import { ClipLoader } from "react-spinners";
import moment from "moment-timezone";

const PermissionGroups = ({ enterpriseDetails }) => {
  const [groupName, setGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedDatabase, setSelectedDatabase] = useState(null);
  const [availablePermissions, setAvailablePermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [showApiKey, setShowApiKey] = useState(null);
  const [showPermissionGroupForm, setShowPermissionGroupForm] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addPermissionGroup,
    onSuccess: () => {
      queryClient.invalidateQueries(["enterpriseDetails"]);
      toast.success("Permission group added successfully");
      setGroupName("");
      setSelectedDatabase(null);
      setShowPermissionGroupForm(false); // Close the form after successful addition
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message || "Error adding permission group";
      toast.error(errorMessage);
    },
  });

  const handleAddPermissionGroup = () => {
    if (!groupName || !selectedDatabase) {
      toast.error("Group name and database are required");
      return;
    }

    const selectedDatabaseObj = enterpriseDetails.databases.find(
      (db) => db.database_id.toString() === selectedDatabase.toString()
    );

    if (!selectedDatabaseObj) {
      toast.error("Selected database not found");
      return;
    }

    mutation.mutate({
      enterprise_id: enterpriseDetails.enterprise.enterprise_id,
      group_name: groupName,
      database_id: selectedDatabase,
      database_name: selectedDatabaseObj.database_name,
      database_type: selectedDatabaseObj.database_type,
    });
  };

  const handleRegenerateApiKey = (group_id) => {
    return regenerateApiKey({ group_id })
      .then((response) => {
        toast.success("API key regenerated. Please save it securely.");
        queryClient.invalidateQueries(["enterpriseDetails"]);
        return response.api_key;
      })
      .catch((error) => {
        toast.error("Failed to regenerate API key");
        throw error;
      });
  };

  const handleAddPermissionToGroup = (permission_id) => {
    addPermissionToGroup({ group_id: selectedGroup.group_id, permission_id })
      .then(() => {
        toast.success("Permission added to group");
        queryClient.invalidateQueries(["enterpriseDetails"]);
        setAvailablePermissions((prevPermissions) =>
          prevPermissions.filter((perm) => perm.permission_id !== permission_id)
        );
        setSelectedPermissions((prevPermissions) => [
          ...prevPermissions,
          availablePermissions.find(
            (perm) => perm.permission_id === permission_id
          ),
        ]);
      })
      .catch((error) => {
        toast.error("Failed to add permission to group");
      });
  };

  const handleRemovePermissionFromGroup = (permission_id) => {
    removePermissionFromGroup({
      group_id: selectedGroup.group_id,
      permission_id,
    })
      .then(() => {
        toast.success("Permission removed from group");
        queryClient.invalidateQueries(["enterpriseDetails"]);
        setAvailablePermissions((prevPermissions) => [
          ...prevPermissions,
          selectedPermissions.find(
            (perm) => perm.permission_id === permission_id
          ),
        ]);
        setSelectedPermissions((prevPermissions) =>
          prevPermissions.filter((perm) => perm.permission_id !== permission_id)
        );
      })
      .catch((error) => {
        toast.error("Failed to remove permission from group");
      });
  };

  useEffect(() => {
    setAvailablePermissions(enterpriseDetails.permissions);
  }, [enterpriseDetails.permissions]);

  const handleGroupSelection = (groupId) => {
    const selected = enterpriseDetails.permissionGroups.find(
      (group) => group.group_id === parseInt(groupId)
    );
    setSelectedGroup(selected);
    setSelectedPermissions(selected ? selected.permissions : []);

    const selectedPermissionIds = new Set(
      selected ? selected.permissions.map((perm) => perm.permission_id) : []
    );

    setAvailablePermissions(
      enterpriseDetails.permissions.filter(
        (perm) => !selectedPermissionIds.has(perm.permission_id)
      )
    );
  };

  const getLastUsedTime = (groupId) => {
    const groupLogs = enterpriseDetails.logs.filter(
      (log) => log.group_id === groupId
    );
    if (groupLogs.length > 0) {
      const lastLog = groupLogs.reduce((prev, current) =>
        prev.execution_time > current.execution_time ? prev : current
      );
      return lastLog.execution_time;
    }
    return null;
  };

  const deleteGroupMutation = useMutation({
    mutationFn: deletePermissionGroup,
    onSuccess: () => {
      queryClient.invalidateQueries(["enterpriseDetails"]);
      toast.success("Permission group deleted successfully");
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message || "Error deleting permission group";
      toast.error(errorMessage);
    },
  });

  const handleDeleteGroup = (groupId) => {
    deleteGroupMutation.mutate({ group_id: groupId });
  };

  return (
    <div className="w-full h-full p-8 flex flex-col">
      <div className="w-full flex flex-row items-center justify-between relative">
        <h1 className="text-white font-geist text-2xl">Permission Groups</h1>
        <div>
          <Button
            onClick={() => setShowPermissionGroupForm(!showPermissionGroupForm)}
          >
            <div className="w-full items-center flex flex-row justify-center gap-2">
              Add Permission Group
              {showPermissionGroupForm ? (
                <FaChevronUp className="text-sm" />
              ) : (
                <FaChevronDown className="text-sm" />
              )}
            </div>
          </Button>
        </div>
        <AnimatePresence>
          {showPermissionGroupForm && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="absolute top-12 z-20 right-0 bg-[#1A1A1A] border border-[#2F2F2F] rounded-lg shadow-sm shadow-black"
            >
              <PermissionGroupForm
                groupName={groupName}
                setGroupName={setGroupName}
                selectedDatabase={selectedDatabase}
                setSelectedDatabase={setSelectedDatabase}
                databases={enterpriseDetails.databases}
                handleAddPermissionGroup={handleAddPermissionGroup}
                isLoading={mutation.isLoading} // Pass loading state
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="overflow-auto lg:overflow-visible flex flex-col mt-6">
        <div className="w-full flex flex-row bg-[#1A1A1A] text-[#AAAAAA] font-dm font-medium rounded-lg ">
          <h1 className="w-[25%] py-3 px-6 hover:text-[#BBBBBD] transition-all transition-200">
            Name
          </h1>
        </div>
        {enterpriseDetails.permissionGroups.map((group) => (
          <PermissionGroupRow
            key={group.group_id}
            group={group}
            onGenerateApiKey={handleRegenerateApiKey}
            onSelectGroup={handleGroupSelection}
            selectedGroup={selectedGroup}
            lastUsedTime={getLastUsedTime(group.group_id)}
            availablePermissions={availablePermissions}
            selectedPermissions={selectedPermissions}
            handleAddPermissionToGroup={handleAddPermissionToGroup}
            handleRemovePermissionFromGroup={handleRemovePermissionFromGroup}
            onDeleteGroup={handleDeleteGroup} // Pass the onDeleteGroup function here
          />
        ))}
      </div>
      {/* Once lay toast here */}
    </div>
  );
};

const PermissionGroupForm = ({
  groupName,
  setGroupName,
  selectedDatabase,
  setSelectedDatabase,
  databases,
  handleAddPermissionGroup,
  isLoading,
}) => {
  const [formError, setFormError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError("");

    if (!groupName.trim()) {
      setFormError("Group name is required");
      return;
    }

    if (!selectedDatabase) {
      setFormError("Please select a database");
      return;
    }

    handleAddPermissionGroup();
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="min-w-[300px] w-full max-w-md h-full p-6 flex flex-col bg-[#1A1A1A] text-white rounded-lg shadow-lg"
    >
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2">Create New Permission Group</h2>
        <p className="text-sm text-[#AAAAAA]">
          Fill in the details below to add a new permission group.
        </p>
      </div>

      <div className="mb-4">
        <Input
          label="Group Name"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          placeholder="Group Name"
        />
      </div>

      <div className="mb-4">
        <Select
          label="Database"
          value={selectedDatabase || ""}
          onChange={(e) => {
            setSelectedDatabase(e.target.value);
            console.log(e.target.value);
          }}
          options={[
            { key: "", label: "Select Database", value: "" },
            ...databases.map((db) => ({
              key: db.database_id,
              label: db.database_name,
              value: db.database_id,
            })),
          ]}
        />
      </div>

      {formError && <p className="text-red-500 mb-4">{formError}</p>}

      <Button type="submit" variation="primary" disabled={isLoading}>
        {isLoading ? (
          <ClipLoader size={20} color="#fff" />
        ) : (
          "Add Permission Group"
        )}
      </Button>
    </form>
  );
};

export default PermissionGroups;
