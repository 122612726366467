import React, { useState, useMemo, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment-timezone";
import { FaShieldAlt, FaExclamationTriangle } from "react-icons/fa";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import Button from "../Button";
import ActiveResponse from "./ActiveResponse";
import SuspiciousQueryItem from "./SuspiciousQueryItem";
import getReadableTimezone from "../../utils/timezone";

const generateDataFromLogs = (logs, timeRange) => {
  const data = [];
  const now = moment().startOf("hour");
  const timeIntervals = {};
  const userTimezone = moment.tz.guess();
  const readableTimezone = getReadableTimezone(userTimezone);

  const hoursMap = {
    "24h": 24,
    "7d": 24 * 7,
  };

  const totalIntervals = hoursMap[timeRange];
  for (let i = 0; i < totalIntervals; i++) {
    const time = moment(now).subtract(i, "hours");
    const timeKey = time.format("YYYY-MM-DD HH:00:00");
    timeIntervals[timeKey] = {
      queries: 0,
      success: 0,
      error: 0,
      anomaly: 0,
      displayTime: time.format("YYYY-MM-DD HH:mm:ss"),
      timezone: readableTimezone,
    };
    data.push({
      time: timeKey,
      queries: 0,
      success: 0,
      error: 0,
      anomaly: 0,
      displayTime: time.format("YYYY-MM-DD HH:mm:ss"),
      timezone: readableTimezone,
    });
  }

  logs.forEach((log) => {
    const executionTime = moment(log.execution_time)
      .local()
      .startOf("hour")
      .format("YYYY-MM-DD HH:00:00");

    if (timeIntervals[executionTime] !== undefined) {
      timeIntervals[executionTime].queries += 1;
      if (log.status === "success") {
        timeIntervals[executionTime].success += 1;
      } else {
        timeIntervals[executionTime].error += 1;
      }
      if (log.anomaly_score > 0.5) {
        timeIntervals[executionTime].anomaly += 1;
      }
    }
  });

  data.forEach((item) => {
    item.queries = timeIntervals[item.time].queries;
    item.success = timeIntervals[item.time].success;
    item.error = timeIntervals[item.time].error;
    item.anomaly = timeIntervals[item.time].anomaly;
  });

  return data.reverse();
};

const BreachControl = ({ enterpriseDetails }) => {
  const [activeView, setActiveView] = useState("monitoring");
  const [timeRange, setTimeRange] = useState("24h");
  const [data, setData] = useState([]);
  const queryClient = useQueryClient();

  const { logs, permissionGroups, permissions, apiKeys, databases } =
    enterpriseDetails;

  useEffect(() => {
    if (logs) {
      setData(generateDataFromLogs(logs, timeRange));
    }
  }, [logs, timeRange]);

  const anomalyScore = useMemo(() => {
    const totalQueries = data.reduce((acc, log) => acc + log.queries, 0);
    const anomalies = data.reduce((acc, log) => acc + log.anomaly, 0);
    return totalQueries > 0 ? Math.round((anomalies / totalQueries) * 100) : 0;
  }, [data]);

  const recentSuspiciousActivities = useMemo(
    () =>
      logs
        .filter((log) => log.anomaly_score > 0.5)
        .filter((log) => {
          const now = moment();
          const logTime = moment(log.execution_time).local();
          if (timeRange === "24h") {
            return now.diff(logTime, "hours") <= 24;
          } else if (timeRange === "7d") {
            return now.diff(logTime, "days") <= 7;
          }
          return false;
        })
        .sort(
          (a, b) =>
            moment(b.execution_time).valueOf() -
            moment(a.execution_time).valueOf()
        ),
    [logs, timeRange]
  );

  const renderMonitoring = () => (
    <div className="flex flex-col w-full gap-4">
      <div className="col-span-2 bg-[#111011] border border-[#2F2F2F] p-4 rounded-lg shadow-md">
        <h3 className="text-xl font-bold mb-2 text-white">Query Activity</h3>
        <ResponsiveContainer width="100%" height={280}>
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorSuccess" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0070f3" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#0070f3" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorError" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#800080" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#800080" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorAnomaly" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ff0000" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#ff0000" stopOpacity={0} />
              </linearGradient>
            </defs>

            <YAxis
              hide
              domain={[0, Math.max(...data.map((item) => item.queries)) + 10]}
              stroke="#666"
            />
            <XAxis
              dataKey="time"
              stroke="#666"
              axisLine={false}
              tickLine={false}
              tick={false}
            />
            <Tooltip
              contentStyle={{ backgroundColor: "#222", border: "none" }}
              labelFormatter={(label, payload) => {
                if (payload && payload.length > 0) {
                  const { displayTime, timezone } = payload[0].payload;
                  return `${moment(displayTime).format(
                    "MMM D, YYYY h:mm A"
                  )} (${timezone})`;
                }
                return label;
              }}
              formatter={(value, name, props) => [value, name]}
            />
            <Area
              type="monotone"
              dataKey="anomaly"
              stroke="#ff0000"
              fillOpacity={1}
              fill="url(#colorAnomaly)"
              name="Anomalies"
            />
            <Area
              type="monotone"
              dataKey="error"
              stroke="#800080"
              fillOpacity={1}
              fill="url(#colorError)"
              name="Failed Queries"
            />
            <Area
              type="monotone"
              dataKey="success"
              stroke="#0070f3"
              fillOpacity={1}
              fill="url(#colorSuccess)"
              name="Successful Queries"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="bg-[#111011] w-full border border-[#2F2F2F] p-4 rounded-lg shadow-md">
        <h3 className="text-xl font-bold mb-2 text-white">
          Recent Suspicious Activities
        </h3>
        <div className="space-y-4">
          {recentSuspiciousActivities.map((activity, index) => (
            <SuspiciousQueryItem key={index} activity={activity} />
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-full text-white p-8">
      <header className="flex justify-between items-center mb-8">
        <h1 className="text-white font-geist text-2xl">Breach Control</h1>
        <div className="flex items-center space-x-4 w-1/2">
          <Button
            onClick={() => setActiveView("monitoring")}
            variation={activeView === "monitoring" ? "primary" : "secondary"}
          >
            <FaShieldAlt className="mr-2" /> Monitoring
          </Button>
          <Button
            onClick={() => setActiveView("activeResponse")}
            variation={
              activeView === "activeResponse" ? "primary" : "secondary"
            }
          >
            <FaExclamationTriangle className="mr-2" /> Active Response
          </Button>
        </div>
      </header>

      <div className="flex mb-4 gap-8 justify-between items-center">
        <div className="space-x-2 flex flex-row">
          <Button
            onClick={() => setTimeRange("24h")}
            variation={timeRange === "24h" ? "primary" : "secondary"}
          >
            24h
          </Button>
          <Button
            onClick={() => setTimeRange("7d")}
            variation={timeRange === "7d" ? "primary" : "secondary"}
          >
            7d
          </Button>
        </div>
        <div className="space-x-2 flex flex-row items-center">
          <h3 className="text-sm font-geist text-white">Anomaly Detection</h3>
          <div className="text-lg font-bold text-center">
            <span
              className={anomalyScore > 50 ? "text-red-500" : "text-green-500"}
            >
              {anomalyScore}%
            </span>
          </div>
        </div>
      </div>
      {activeView === "monitoring" && renderMonitoring()}
      {activeView === "activeResponse" && (
        <ActiveResponse
          enterpriseDetails={enterpriseDetails}
          recentSuspiciousActivities={recentSuspiciousActivities}
        />
      )}
      {/* Once lay toast here */}
    </div>
  );
};

export default BreachControl;
