import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEnterprises,
  createEnterprise,
  deleteEnterprise,
} from "../../utils/apiFunctions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import Button from "../Button";
import Input from "../Input";
import OrganizationCard from "./OrganizationCard";

const OrganizationForm = ({ mutation }) => {
  const [enterpriseName, setEnterpriseName] = useState("");
  const [type, setType] = useState("free");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!enterpriseName) {
      toast.error("Please enter a name for the new organization");
      return;
    }

    mutation.mutate({
      enterprise_name: enterpriseName,
      type: type,
    });
    setEnterpriseName("");
    setType("free");
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="min-w-[300px] w-full max-w-md h-full p-6 flex flex-col bg-[#1A1A1A] text-white rounded-lg shadow-lg"
    >
      <div className="mb-6">
        <h2 className="text-xl font-bold mb-2">Add New Organization</h2>
        <p className="text-sm text-[#AAAAAA]">
          Fill in the details below to add a new organization.
        </p>
      </div>

      <div className="mb-4">
        <Input
          label="Organization Name"
          value={enterpriseName}
          onChange={(e) => setEnterpriseName(e.target.value)}
          placeholder="Organization Name"
        />
      </div>

      <Button type="submit" variation="primary">
        Add Organization
      </Button>
    </form>
  );
};

const Organizations = ({ setSelectedEnterprise, setRole }) => {
  const [organizationFormOpen, setOrganizationFormOpen] = useState(false);
  const { userSegment } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Fetch enterprises
  const { data, error, isLoading } = useQuery({
    queryKey: ["enterprises"],
    queryFn: getEnterprises,
    initialData: [],
  });

  // Ensure data is an array
  const organizations = Array.isArray(data) ? data : [];

  // Mutation for creating a new enterprise
  const createMutation = useMutation({
    mutationFn: createEnterprise,
    onSuccess: () => {
      queryClient.invalidateQueries(["enterprises"]); // Invalidate and refetch enterprises
      toast.success("Enterprise created successfully");
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message || "Error creating enterprise";
      toast.error(errorMessage);
      console.error("Error creating enterprise:", errorMessage);
    },
  });

  // Mutation for deleting an enterprise
  const deleteMutation = useMutation({
    mutationFn: deleteEnterprise,
    onSuccess: () => {
      queryClient.invalidateQueries(["enterprises"]); // Invalidate and refetch enterprises
      toast.success("Enterprise deleted successfully");
    },
    onError: (error) => {
      const errorMessage =
        error.response?.data?.message || "Error deleting enterprise";
      toast.error(errorMessage);
      console.error("Error deleting enterprise:", errorMessage);
    },
  });

  const handleDeleteEnterprise = (enterpriseId) => {
    deleteMutation.mutate({ enterprise_id: enterpriseId });
  };

  const handleSelectEnterprise = (enterprise) => {
    console.log("Selected enterprise:", enterprise);
    setSelectedEnterprise(enterprise.enterprise_name);
    setRole(enterprise.role);
    navigate(`/dashboard/${userSegment}/${enterprise.enterprise_name}`);
  };

  if (isLoading)
    return (
      <div className="text-white italic font-dm font-medium">Loading...</div>
    );
  if (error)
    return (
      <div className="text-white italic font-dm font-medium">
        Error fetching enterprises: {error.message}
      </div>
    );

  return (
    <div className="w-full h-full p-8 flex flex-col">
      <div className="w-full flex flex-row items-center justify-between relative">
        <h1 className="text-white font-geist text-2xl">Organizations</h1>
        <div>
          <Button
            onClick={() => setOrganizationFormOpen(!organizationFormOpen)}
          >
            <div className="w-full items-center flex flex-row justify-center gap-2">
              Add Organization
              {organizationFormOpen ? (
                <FaChevronUp className="text-sm" />
              ) : (
                <FaChevronDown className="text-sm" />
              )}
            </div>
          </Button>
        </div>
        <AnimatePresence>
          {organizationFormOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="absolute top-12 z-20 right-0 bg-[#1A1A1A] border border-[#2F2F2F] rounded-lg shadow-sm shadow-black"
            >
              <OrganizationForm mutation={createMutation} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="mt-4 flex flex-row flex-wrap gap-4 w-full">
        {organizations.length > 0 ? (
          organizations.map((org) => (
            <OrganizationCard
              key={org.enterprise_id}
              enterprise={org}
              onClick={() => handleSelectEnterprise(org)}
              onDelete={handleDeleteEnterprise}
            />
          ))
        ) : (
          <div className="text-[#AAAAAA] text-sm font-dm font-light">
            No organizations found.
          </div>
        )}
      </div>
    </div>
  );
};

export default Organizations;
