import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../components/Dashboard/Sidebar";
import Organizations from "../components/Dashboard/Organizations";
import { useQuery } from "@tanstack/react-query";
import { getEnterpriseDetails } from "../utils/apiFunctions";
import generateUserUrlSegment from "../utils/urlSegment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ThirtyFirst from "../assets/ThirtyFirst.svg";
import Users from "../components/Dashboard/Users";
import Databases from "../components/Dashboard/Databases";
import Permissions from "../components/Dashboard/Permissions";
import PermissionGroups from "../components/Dashboard/PermissionGroup";
import Logging from "../components/Dashboard/Logging";
import OrganizationDashboard from "../components/Dashboard/OrganizationDashboard";
import BreachControl from "../components/Dashboard/BreachControl";
import ApiDocumentation from "../components/Dashboard/ApiDocumentation";

const Dashboard = () => {
  const { userSegment, enterpriseSegment } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("organizations");
  const [selectedEnterprise, setSelectedEnterprise] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const user = useSelector((state) => state.auth.user);

  const { data: enterpriseDetails, error } = useQuery({
    queryKey: ["enterpriseDetails", enterpriseSegment],
    queryFn: () => getEnterpriseDetails(enterpriseSegment),
    enabled: !!enterpriseSegment,
  });

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }

    const userUrlSegment = generateUserUrlSegment(user.email);
    if (userSegment !== userUrlSegment) {
      navigate(`/dashboard/${userUrlSegment}`);
      return;
    }
    if (enterpriseSegment) {
      setSelectedEnterprise(enterpriseSegment);
      setSelectedTab("dashboard");
    } else {
      setSelectedTab("organizations");
      setSelectedEnterprise(null);
      setUserRole(null);
    }
  }, [user, userSegment, enterpriseSegment, navigate]);

  useEffect(() => {
    if (enterpriseDetails && enterpriseDetails.role) {
      setUserRole(enterpriseDetails.role);
    }
  }, [enterpriseDetails]);

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
  };

  if (error) {
    toast.error("Error fetching enterprise details");
  }

  let ContentComponent;
  switch (selectedTab) {
    case "dashboard":
      ContentComponent = (
        <OrganizationDashboard enterpriseDetails={enterpriseDetails} />
      );
      break;
    case "databases":
      ContentComponent = <Databases enterpriseDetails={enterpriseDetails} />;
      break;
    case "permissions":
      ContentComponent = <Permissions enterpriseDetails={enterpriseDetails} />;
      break;
    case "users":
      ContentComponent = <Users enterpriseDetails={enterpriseDetails} />;
      break;
    case "permissionGroups":
      ContentComponent = (
        <PermissionGroups enterpriseDetails={enterpriseDetails} />
      );
      break;
    case "logs":
      ContentComponent = <Logging enterpriseDetails={enterpriseDetails} />;
      break;
    case "breachControl":
      ContentComponent = (
        <BreachControl enterpriseDetails={enterpriseDetails} />
      );
      break;
    case "apiDocumentation":
      ContentComponent = <ApiDocumentation />;
      break;
    default:
      ContentComponent = (
        <Organizations
          setRole={setUserRole}
          setSelectedEnterprise={setSelectedEnterprise}
        />
      );
      break;
  }

  return (
    <div className="w-screen h-screen flex flex-col">
      <div className="w-full h-full flex">
        <Sidebar
          userRole={userRole}
          selectedEnterprise={selectedEnterprise}
          selectedTab={selectedTab}
          setSelectedEnterprise={setSelectedEnterprise}
          onSelectTab={handleSelectTab}
          databases={enterpriseDetails?.databases}
        />
        <div className="w-full h-full p-8 overflow-auto">
          {ContentComponent}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
