import React, { useState, useEffect } from 'react';
import { FaShieldAlt, FaExclamationTriangle } from 'react-icons/fa';
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

const BreachDetectionDashboard = () => {
  const [data, setData] = useState([]);
  const [anomalyScore, setAnomalyScore] = useState(0);

  useEffect(() => {
    const generateData = () => {
      const newData = [];
      for (let i = 0; i < 24; i++) {
        newData.push({
          time: `${i}:00`,
          queries: Math.floor(Math.random() * 100),
          success: Math.floor(Math.random() * 80),
          error: Math.floor(Math.random() * 20),
          anomaly: Math.floor(Math.random() * 10),
        });
      }
      return newData;
    };

    const updateData = () => {
      const newData = generateData();
      setData(newData);
      
      const totalQueries = newData.reduce((acc, log) => acc + log.queries, 0);
      const anomalies = newData.reduce((acc, log) => acc + log.anomaly, 0);
      const newAnomalyScore = totalQueries > 0 ? Math.round((anomalies / totalQueries) * 100) : 0;
      setAnomalyScore(newAnomalyScore);
    };

    updateData();
    const interval = setInterval(updateData, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-text-primary min-w-[800px]">
      <div className="bg-background-card p-4 rounded-lg shadow-md">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <FaShieldAlt className="text-primary" />
            <span className="font-geist font-bold">Query Activity</span>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-sm font-geist">Anomaly Score:</span>
            <span className={`text-lg font-bold ${anomalyScore > 50 ? 'text-accent-error' : 'text-accent-success'}`}>
              {anomalyScore}%
            </span>
          </div>
        </div>
        <ResponsiveContainer width="100%" height={280}>
          <AreaChart data={data}>
            <defs>
              <linearGradient id="colorSuccess" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#0070f3" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#0070f3" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorError" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#800080" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#800080" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorAnomaly" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ff0000" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#ff0000" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="time" stroke="#666" />
            <YAxis hide domain={[0, 'dataMax + 10']} />
            <Tooltip contentStyle={{ backgroundColor: "#222", border: "none" }} />
            <Area type="monotone" dataKey="anomaly" stroke="#ff0000" fillOpacity={1} fill="url(#colorAnomaly)" name="Anomalies" />
            <Area type="monotone" dataKey="error" stroke="#800080" fillOpacity={1} fill="url(#colorError)" name="Failed Queries" />
            <Area type="monotone" dataKey="success" stroke="#0070f3" fillOpacity={1} fill="url(#colorSuccess)" name="Successful Queries" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-4 bg-background-card p-4 rounded-lg shadow-md">
        <div className="flex items-center space-x-2 mb-2">
          <FaExclamationTriangle className="text-accent-warning" />
          <span className="font-geist font-bold">Recent Suspicious Activities</span>
        </div>
        <div className="space-y-2">
          {[...Array(3)].map((_, index) => (
            <div key={index} className="bg-background-primary p-2 rounded">
              <div className="flex justify-between items-center">
                <span className="font-geist text-sm">Suspicious query from IP: 192.168.1.{100 + index}</span>
                <span className="text-xs text-text-secondary">{new Date().toLocaleTimeString()}</span>
              </div>
              <div className="text-xs text-text-secondary mt-1">Anomaly score: {(Math.random() * 0.5 + 0.5).toFixed(2)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BreachDetectionDashboard;