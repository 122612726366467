import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Landing from "./views/Landing.jsx";
import Auth from "./views/Auth.jsx";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./middleware/ProtectedRoute";
import Dashboard from "./views/Dashboard.jsx";
import { useSelector } from "react-redux";
import generateUserUrlSegment from "./utils/urlSegment";
import { Slide, ToastContainer } from "react-toastify";

function App() {
  return (
    <Router>
      <div className="custom-cursor">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Auth type="login" />} />
          <Route path="/signup" element={<Auth type="signup" />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <RedirectToUserDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/:userSegment"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/:userSegment/:enterpriseSegment"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          transition={Slide}
          theme="dark"
        />
      </div>
    </Router>
  );
}

const RedirectToUserDashboard = () => {
  const user = useSelector((state) => state.auth.user);
  if (user) {
    const userSegment = generateUserUrlSegment(user.email);
    return <Navigate to={`/dashboard/${userSegment}`} />;
  }
  return <Navigate to="/login" />;
};

export default App;
