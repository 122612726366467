import React from "react";
import {
  FiDatabase,
  FiServer,
  FiShield,
  FiZap,
  FiLock,
  FiGithub,
  FiTwitter,
  FiLinkedin,
  FiExternalLink,
} from "react-icons/fi";
import ThirtyFirst from "../../assets/ThirtyFirst.svg";

const Node = ({ x, y, icon: Icon, color, size = 30, pulse = false }) => (
  <g transform={`translate(${x}, ${y})`}>
    <circle r={size / 2} fill="#242324" stroke={color} strokeWidth="2" />
    {pulse && (
      <circle
        r={size / 2}
        fill="none"
        stroke={color}
        strokeWidth="2"
        opacity="0.5"
      >
        <animate
          attributeName="r"
          from={size / 2}
          to={size}
          dur="1.5s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="0.5"
          to="0"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </circle>
    )}
    <foreignObject
      x={-size / 4}
      y={-size / 4}
      width={size / 2}
      height={size / 2}
    >
      <div className="w-full h-full flex items-center justify-center">
        <Icon className="w-full h-full" color={color} />
      </div>
    </foreignObject>
  </g>
);

const DataPath = ({ id, d, color, duration }) => (
  <g>
    <path
      id={id}
      d={d}
      stroke={color}
      strokeWidth="1"
      strokeOpacity="0.2"
      fill="none"
    />
    {[...Array(5)].map((_, i) => (
      <circle key={i} r="4" fill={color}>
        <animateMotion
          dur={`${duration}s`}
          repeatCount="indefinite"
          rotate="auto"
          begin={`-${(i * duration) / 5}s`}
        >
          <mpath href={`#${id}`} />
        </animateMotion>
      </circle>
    ))}
  </g>
);

const FooterAnimation = () => (
  <svg
    className="w-full h-40"
    viewBox="0 0 1500 200"
    preserveAspectRatio="xMidYMid meet"
  >
    <DataPath
      id="path1"
      d="M-100,100 Q300,50 700,120 T1600,100"
      color="#0056C8"
      duration="30"
    />
    <DataPath
      id="path2"
      d="M-100,100 Q300,150 700,80 T1600,100"
      color="#F59E0B"
      duration="25"
    />
    <Node x={100} y={100} icon={FiDatabase} color="#0056C8" size={40} pulse />
    <Node x={450} y={70} icon={FiServer} color="#3B82F6" size={30} />
    <Node x={800} y={130} icon={FiShield} color="#F59E0B" size={40} pulse />
    <Node x={1150} y={70} icon={FiZap} color="#0056C8" size={30} />
    <Node x={1400} y={100} icon={FiLock} color="#3B82F6" size={40} pulse />
  </svg>
);

const FooterColumn = ({ title, links }) => (
  <div>
    <h3 className="text-lg font-semibold mb-4 font-clash text-white">
      {title}
    </h3>
    <ul className="space-y-2">
      {links.map((link, index) => (
        <li key={index}>
          <a
            href={link.href}
            className="text-text-secondary hover:text-white transition-colors flex items-center"
          >
            {link.text}
            {link.external && (
              <FiExternalLink className="ml-1 inline-block" size={14} />
            )}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

const Footer = () => {
  const productLinks = [
    { text: "Features", href: "#" },
    { text: "Pricing", href: "#" },
    { text: "Documentation", href: "#" },
    { text: "API Reference", href: "#", external: true },
  ];

  const companyLinks = [
    { text: "About Us", href: "#" },
    { text: "Careers", href: "#" },
    { text: "Contact", href: "#" },
    { text: "Blog", href: "#" },
  ];

  const resourceLinks = [
    { text: "Community Forum", href: "#", external: true },
    { text: "Webinars", href: "#" },
    { text: "Security Center", href: "#" },
    { text: "Status Page", href: "#", external: true },
  ];

  return (
    <footer className="pt-16 pb-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 mb-12">
          <div className="col-span-1 md:col-span-2">
            <img
              src={ThirtyFirst}
              alt="ThirtyFirst Logo"
              className="h-8 mb-4"
            />
            <p className="text-sm text-text-secondary mb-6 max-w-[400px]">
              Empowering your data management with cutting-edge security solutions.
              Protect, control, and optimize your database operations with ThirtyFirst.
            </p>
            <div className="flex space-x-4">
              <a
                href="#"
                className="text-text-secondary hover:text-white transition-colors"
                aria-label="GitHub"
              >
                <FiGithub size={20} />
              </a>
              <a
                href="#"
                className="text-text-secondary hover:text-white transition-colors"
                aria-label="Twitter"
              >
                <FiTwitter size={20} />
              </a>
              <a
                href="#"
                className="text-text-secondary hover:text-white transition-colors"
                aria-label="LinkedIn"
              >
                <FiLinkedin size={20} />
              </a>
            </div>
          </div>
          <FooterColumn title="Product" links={productLinks} />
          <FooterColumn title="Company" links={companyLinks} />
          <FooterColumn title="Resources" links={resourceLinks} />
        </div>
        <div className="relative overflow-hidden mb-8">
          <FooterAnimation />
        </div>
        <div className="text-center text-sm text-text-secondary">
          <p>© {new Date().getFullYear()} ThirtyFirst. All rights reserved.</p>
          <p className="mt-2">
            <a href="#" className="hover:text-white transition-colors">Privacy Policy</a>
            {" | "}
            <a href="#" className="hover:text-white transition-colors">Terms of Service</a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

