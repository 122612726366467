import React, { useEffect, useState } from "react";
import ThirtyFirst from "../../assets/ThirtyFirst.svg";
import {
  FaDatabase,
  FaBook,
  FaCheckCircle,
  FaExclamationCircle,
  FaClock,
  FaServer,
  FaUserFriends,
  FaSearch,
  FaCopy,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import moment from "moment-timezone";
import getReadableTimezone from "../../utils/timezone";

const generateDataFromLogs = (logs) => {
  const data = [];
  const now = moment().startOf("hour");
  const timeIntervals = {};
  const userTimezone = moment.tz.guess();
  const readableTimezone = getReadableTimezone(userTimezone);

  for (let i = 0; i < 24; i++) {
    const time = moment(now).subtract(i, "hours");
    const timeKey = time.format("YYYY-MM-DD HH:00:00");
    timeIntervals[timeKey] = {
      queries: 0,
      success: 0,
      error: 0,
      displayTime: time.format("YYYY-MM-DD HH:mm:ss"),
      timezone: readableTimezone,
    };
    data.push({
      time: timeKey,
      queries: 0,
      success: 0,
      error: 0,
      displayTime: time.format("YYYY-MM-DD HH:mm:ss"),
      timezone: readableTimezone,
    });
  }

  logs.forEach((log) => {
    const executionTime = moment(log.execution_time)
      .local()
      .startOf("hour")
      .format("YYYY-MM-DD HH:00:00");

    if (timeIntervals[executionTime] !== undefined) {
      timeIntervals[executionTime].queries += 1;
      if (log.status === "success") {
        timeIntervals[executionTime].success += 1;
      } else {
        timeIntervals[executionTime].error += 1;
      }
    }
  });

  data.forEach((item) => {
    item.queries = timeIntervals[item.time].queries;
    item.success = timeIntervals[item.time].success;
    item.error = timeIntervals[item.time].error;
  });

  return data.reverse();
};

const COLORS = ["#0070f3", "#800080"];

const DatabaseActivity = ({ logs }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (logs && logs.length > 0) {
      const last24HoursLogs = logs.filter((log) =>
        moment(log.execution_time).isAfter(moment().subtract(24, "hours"))
      );
      setData(generateDataFromLogs(last24HoursLogs));
    }
  }, [logs]);

  return (
    <ResponsiveContainer width="100%" height={200}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="colorSuccess" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0070f3" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#0070f3" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorError" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#800080" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#800080" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="time" tick={false} hide />
        <YAxis hide />
        <Tooltip
          contentStyle={{
            backgroundColor: "#222",
            border: "1px solid #111011",
            borderRadius: "8px",
          }}
          labelFormatter={(label, payload) => {
            if (payload && payload.length > 0) {
              const { displayTime, timezone } = payload[0].payload;
              return `${moment(displayTime).format(
                "MMM D, YYYY h:mm A"
              )} (${timezone})`;
            }
            return label;
          }}
          formatter={(value, name, props) => [value, name]}
        />
        <Area
          type="monotone"
          dataKey="error"
          stroke="#800080"
          fillOpacity={1}
          fill="url(#colorError)"
          name="Failed Queries"
        />
        <Area
          type="monotone"
          dataKey="success"
          stroke="#0070f3"
          fillOpacity={1}
          fill="url(#colorSuccess)"
          name="Successful Queries"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const LogEntry = ({ log, isExpanded, onToggle }) => {
  const { status, query, execution_time, user_ip, database_name, group_name } =
    log;

  const formattedExecutionTime = moment(execution_time).format(
    "YYYY-MM-DD HH:mm:ss"
  );

  const color = status === "success" ? "text-blue-500" : "text-red-500";
  const border = status === "success" ? "border-zinc-700" : "border-red-500";

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // You could add a toast notification here
  };

  return (
    <div
      className={`bg-zinc-900 border ${border} rounded-lg px-3 py-4 shadow-md hover:shadow-lg transition-shadow duration-300 mb-2`}
    >
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={onToggle}
      >
        <div className="flex items-center gap-2">
          <FaBook className={`${color} text-sm`} />
          <p className="text-xs text-white">
            <span className="font-bold">{status}</span>
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-xs text-gray-400">
            {moment(execution_time).format("MM/DD HH:mm")} - {user_ip}
          </p>
          <p className="items-center text-center text-xs">
            {isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </p>
        </div>
      </div>
      {isExpanded && (
        <div className="mt-4 flex flex-col gap-3 text-xs">
          <p>
            <span className="font-bold">Database:</span> {database_name}
          </p>
          <p>
            <span className="font-bold">Group:</span> {group_name}
          </p>
          <p>
            <span className="font-bold">Execution Time:</span>{" "}
            {formattedExecutionTime}
          </p>
          <div className="mt-1 bg-[#242424] p-2 rounded relative">
            <pre className="whitespace-pre-wrap break-words">{query}</pre>
            <button
              onClick={() => copyToClipboard(query)}
              className="absolute top-1 right-1 text-gray-400 hover:text-white"
            >
              <FaCopy />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const OrganizationDashboard = ({ enterpriseDetails }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [expandedLogs, setExpandedLogs] = useState({});
  const [sortOrder, setSortOrder] = useState("desc");

  if (!enterpriseDetails) return null;

  const { enterprise, logs, users, databases } = enterpriseDetails;

  const logsWithDatabaseName = logs.map((log) => ({
    ...log,
    database_name: databases.find((db) => db.database_id === log.database_id)
      ?.database_name,
  }));

  const sortedLogs = logsWithDatabaseName
    .sort((a, b) => new Date(b.execution_time) - new Date(a.execution_time))
    .filter(
      (log) => new Date(log.execution_time) > Date.now() - 24 * 60 * 60 * 1000
    );


  const filteredLogs = sortedLogs.filter(
    (log) =>
      (statusFilter === "all" || log.status === statusFilter) &&
      (log.query.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.user_ip.includes(searchTerm) ||
        log.database_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        log.group_name.toLowerCase().includes(searchTerm.toLowerCase()))
  );


  const toggleLogExpansion = (logId) => {
    setExpandedLogs((prev) => ({
      ...prev,
      [logId]: !prev[logId],
    }));
  };

  const handleSort = () => {
    setSortOrder((prev) => (prev === "desc" ? "asc" : "desc"));
  };

  const successLogs = sortedLogs.filter((log) => log.status === "success");
  const errorLogs = sortedLogs.filter((log) => log.status !== "success");

  const successPercentage = (successLogs.length / sortedLogs.length) * 100;
  const errorPercentage = 100 - successPercentage;

  const pieData = [
    { name: "Success", value: successPercentage },
    { name: "Error", value: errorPercentage },
  ];

  const avgQueriesPerHour = (sortedLogs.length / 24).toFixed(2);

  return (
    <div className="w-full h-screen max-h-full flex flex-col text-white">
      <header className="flex items-center justify-between w-full p-3 border-b border-[#242324]">
        <div className="flex items-center">
          <img
            src={ThirtyFirst}
            alt="Logo"
            className="w-6 h-6 object-contain mr-3"
          />
          <h1 className="text-xl font-bold">{enterprise.enterprise_name}</h1>
        </div>
        <span className="text-sm font-light text-gray-400">
          {enterprise.type[0].toUpperCase() + enterprise.type.slice(1)} Plan
        </span>
      </header>

      <main className="flex-grow grid grid-cols-12 gap-4 p-4 overflow-hidden">
        <section className="col-span-8 bg-[#111011] border border-[#242424] rounded-lg p-4 shadow-lg">
          <h2 className="text-lg font-medium mb-2">Database Activity</h2>
          <DatabaseActivity logs={sortedLogs} />
          <div className="grid grid-cols-4 gap-4 mt-4">
            <div className="bg-[#242424] p-3 rounded-lg">
              <div className="flex items-center justify-between">
                <FaCheckCircle className="text-green-500" />
                <span className="text-lg font-bold">
                  {successPercentage.toFixed(1)}%
                </span>
              </div>
              <p className="text-xs mt-1">Success Rate</p>
            </div>
            <div className="bg-[#242424] p-3 rounded-lg">
              <div className="flex items-center justify-between">
                <FaExclamationCircle className="text-red-500" />
                <span className="text-lg font-bold">
                  {errorPercentage.toFixed(1)}%
                </span>
              </div>
              <p className="text-xs mt-1">Error Rate</p>
            </div>
            <div className="bg-[#242424] p-3 rounded-lg">
              <div className="flex items-center justify-between">
                <FaDatabase className="text-blue-500" />
                <span className="text-lg font-bold">{avgQueriesPerHour}</span>
              </div>
              <p className="text-xs mt-1">Avg Queries/Hour</p>
            </div>
            <div className="bg-[#242424] p-3 rounded-lg">
              <div className="flex items-center justify-between">
                <FaClock className="text-yellow-500" />
                <span className="text-lg font-bold">{sortedLogs.length}</span>
              </div>
              <p className="text-xs mt-1">Total Queries (24h)</p>
            </div>
          </div>
        </section>

        <section className="col-span-4 bg-[#111011] border border-[#242424] rounded-lg p-4 shadow-lg flex flex-col">
          <h2 className="text-lg font-medium mb-2">Quick Stats</h2>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="bg-[#242424] p-3 rounded-lg">
              <div className="flex items-center justify-between">
                <FaUserFriends className="text-purple-500" />
                <span className="text-lg font-bold">{users.length}</span>
              </div>
              <p className="text-xs mt-1">Total Users</p>
            </div>
            <div className="bg-[#242424] p-3 rounded-lg">
              <div className="flex items-center justify-between">
                <FaServer className="text-green-500" />
                <span className="text-lg font-bold">{databases.length}</span>
              </div>
              <p className="text-xs mt-1">Total Databases</p>
            </div>
          </div>
          <h3 className="text-sm font-medium mb-2">Query Success Rate</h3>
          <div className="flex-grow">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  innerRadius={30}
                  outerRadius={50}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  contentStyle={{
                    backgroundColor: "#222",
                    border: "1px solid #111011",
                    color: "#ffffff",
                    borderRadius: "8px",
                  }}
                  itemStyle={{ color: "#ffffff" }}
                  labelStyle={{ color: "#ffffff" }}
                />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </section>

        <section className="col-span-12 min-h-[300px] overflow-hidden bg-[#111011] border border-[#242424] rounded-lg p-4 shadow-lg">
          <div className="flex justify-between items-center mb-3">
            <h2 className="text-lg font-medium">Recent Logs</h2>
            <div className="flex items-center gap-2">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search logs..."
                  className="bg-[#242424] text-white text-sm rounded-lg pl-8 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
              <select
                className="bg-[#242424] text-white text-sm rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="all">All Status</option>
                <option value="success">Success</option>
                <option value="failed">Failed</option>
              </select>
            </div>
          </div>
          <div
            className="overflow-y-auto"
            style={{ maxHeight: "calc(100vh - 500px)" }}
          >
            {filteredLogs.map((log) => (
              <LogEntry
                key={log.log_id}
                log={log}
                isExpanded={expandedLogs[log.log_id]}
                onToggle={() => toggleLogExpansion(log.log_id)}
              />
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default OrganizationDashboard;